import React from 'react';
import { Button, Message } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";

import API_service from "xAppLib/providers/API_service";
import instcons_global_model from "models/instcons_global_model";
import Alert from "../NUI/Alert";
import { AsyncButton } from "../UIelems/AsyncButton";
import { from_melb_ui_tm } from "helpers/datetime";
import { rejectOnResErr } from "../icosm/treatment/utils";
import { useAsyncUpdate } from "xAppLib/Hooks/useAsync";
import { useProfile } from "xAppLib/Hooks";
import { useSubscription } from "xAppLib/Hooks/useSubscription";

export function CouldNotCallConsults() {
	const [prof] = useProfile();

	return (prof?.hist ?? [])
		.filter(rec => rec.status === 'could_not_call')
		.map(rec => <CouldNotCallConsult key={rec.sid} rec={rec}/>);
}

function CouldNotCallConsult({rec}) {
	const {pathname} = useLocation();

	const {sid, spd_hash__mobile: mobilehash = '_', spd_data} = rec;
	const prefix = `/docconsreq`;
	const manage_href = `${prefix}/${sid}/${mobilehash}`;
	const consult_date = from_melb_ui_tm(rec.add_tm).format('MMM D, YYYY');
	const pt_name = app.user?.profs?.length > 1 && (
		<>{spd_data?.first_name} {spd_data?.last_name} – </>
	);

	const action = useAsyncUpdate(
		async (act) => await API_service.load_data('doccons_req', {act, sid, mobilehash}).then(rejectOnResErr),
	);

	const callAgain = async () => {
		await action.fn('recall_same_number');
		app.trigger(
			app.events.SHOW_MESSAGE,
			'Your call back request has been received',
			'We will try to call you again shortly.',
			'positive',
		);
	};

	const cancelConsult = async () => {
		if ('yes' !== await app.confirm('Cancel consultation', 'Are you sure you want to cancel this consultation?')) {
			return;
		}

		await action.fn('cancel_consult');
		app.trigger(
			app.events.SHOW_MESSAGE,
			'Your consultation has been cancelled',
			'Any payment made will be refunded.',
			'positive',
		);
	};

	const {data: frdb} = useSubscription(onData => instcons_global_model.watch_record(sid, onData), [sid]);

	if (frdb?.status !== 'could_not_call') {
		return null;
	}

	if (pathname.startsWith(prefix)) {
		return null;
	}

	const error = action.error?.message ?? action.error?.error ?? action.error;

	return (
		<Message info>
			<Message.Header>
				<div className="flex flex-col space-y-2">
					<span>Consultation Booking</span>
					<small className="dates uppercase">
						{pt_name} {consult_date}
					</small>
				</div>
			</Message.Header>

			<Message.Content className="mt-2 space-y-4">
				<p>We're sorry we haven't been able to get in touch with you.</p>
				<p>Please choose from the options below to let us know if you'd still like one of our doctors to call you.</p>

				<div className="space-x-2">
					<AsyncButton disabled={action.loading} onClick={callAgain} primary data-testid="call-again-action">
						Please call me
					</AsyncButton>

					<AsyncButton disabled={action.loading} onClick={cancelConsult} negative basic data-testid="cancel-consult-action">
						Cancel consultation
					</AsyncButton>

					<Button to={manage_href} disabled={action.loading} as={Link} basic data-testid="manage-consult-action">
						Consultation details
					</Button>

				</div>
				{action.error && (
					<Alert error header="Something went wrong" testId="return-to-wr-error">
						<div className="space-y-4">
							{Boolean(error) && <div>{String(error)}</div>}
							<div>Please try again, or <ContactUs/> if issues persist.</div>
						</div>
					</Alert>
				)}
			</Message.Content>
		</Message>
	);
}

function ContactUs({children = 'contact us'}) {
	const openContactModal = e => {
		e.preventDefault();
		app.trigger(app.events.SHOW_POPUP, {pt: "contact", txt: 'Contact Us', u: 'contact-us'})
	};

	return <a href="#" className="underline" onClick={openContactModal}>{children}</a>;
}
