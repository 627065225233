const containsOneUniqueValue = (arr) => {
    return [...new Set(arr)].length === 1
}

const orderAndDeliveryStatus = (evermed) => {
    const orderLineItems = evermed?.order?.lines || []
    const allStatusesPresent = orderLineItems.every(line => line?.lineStatus && line?.packageStatus)

    if (allStatusesPresent) {
        const lineStatuses = orderLineItems.map(line => line.lineStatus)
        const packageStatuses = orderLineItems.map(line => line.packageStatus)
        return {
            lineStatus: containsOneUniqueValue(lineStatuses) ? lineStatuses[0] : 'Multiple Statuses - Please check',
            packageStatus: containsOneUniqueValue(packageStatuses) ? packageStatuses[0] : 'Multiple Statuses - Please check'
        }
    }

    return {
        lineStatus: evermed?.orderStatus || 'Status not available',
        packageStatus: evermed?.deliveryStatus || 'Status not available'
    }
}

const getSelectedDeliveryMethod = (evermed, deliveryCode) => {
    const flat_del_ops = evermed.do?.map((method) => method.options).flat() || [];
    return flat_del_ops.find((opt) => opt.code === deliveryCode?.replace('EM_', ''));
}

const computeDeliveryMethodsToShow = (evermed) => {
    if(!evermed?.do?.length) return [];
    const deliveryCategoriesToOptions = evermed?.do?.reduce((acc, method) => {
        acc[method.displayName] = method.options;
        return acc;
    }, {});
    const doorDashOptions = deliveryCategoriesToOptions['DoorDash'] || [];
    const doorDashHasATL = doorDashOptions.some((opt) => !!opt.hasAuthorityToLeave);
    const presentableDoorDashOptions = doorDashOptions.slice(0, doorDashHasATL ? 1 : 3);
    delete deliveryCategoriesToOptions['DoorDash'];
    const presentableOptions = Object.values(deliveryCategoriesToOptions).flat().concat(presentableDoorDashOptions);
    return presentableOptions.sort((a, b) => new Date(a.estimatedLatestDeliveryDate) - new Date(b.estimatedLatestDeliveryDate));
}

export {
    orderAndDeliveryStatus,
    getSelectedDeliveryMethod, 
    computeDeliveryMethodsToShow
}