import React from 'react';
import { Link } from 'react-router-dom'

import { FirebaseX } from "@ionic-native/firebase-x";

import firebaseApp from 'xAppLib/providers/firebase.js';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
// import firebase_auth from 'providers/firebase_auth.js';
// import firebase_database from 'providers/firebase_database.js';

import org_model from 'models/org_model'
// import device_model from 'models/device_model'
// import debounce from 'libs/debounce'

import API_service from 'xAppLib/providers/API_service'

import logger from 'xAppLib/libs/logger'

import ActBtns from 'views/UIelems/fields/ActBtns'

import localstorage_database from 'xAppLib/providers/localstorage_database.js';

import firebase_conf from 'conf/firebase_conf';
import gtm from '../xAppLib/providers/gtm';
import ReminderNotificationController from '../views/reminders/ReminderNotificationController';

const DEBUG = false

const MESSAGING_ASKED = 'msg_asked'

export default class messaging_model {
	
	
	static async init () {
		
		if (!this.support_noti()) {
			DEBUG && console.log('Messaging Not Supported');
			return false
		}
		
		this.watchChanges()

		await this.listen()
		
		const notifications = await this.check_noti_perms()
		app.notifications = notifications
		app.trigger(app.events.CONTEXT, 'notifications',notifications)

		DEBUG && console.log('Messaging Init');

		//this.maybe_ask()

	}
	
	static async listen() {
		
		if (app.settings.is_ionic) 
			await this.listen_nat_frbX()
		else if (isSupported())
			await this.listen_js()

	}

	
	
	static async maybe_ask() {
		
		
		const messaging_asked = localstorage_database.get_record(MESSAGING_ASKED) || false
		if (!messaging_asked) {
			localstorage_database.update_record(MESSAGING_ASKED, true)
			setTimeout(_=>this.req_noti_perms(),1000)
		}

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async listen_nat_frbX () {
		
		const { allowed } = await this.check_noti_perms()

		if (!allowed) {
			DEBUG && console.log('No notifications...', allowed);
			return
		}
		
		
		FirebaseX.onTokenRefresh().subscribe(fcmToken => {
			app.mess_tok = fcmToken;
			this.send_mess_tok({tok_age:'refr'})
			DEBUG && console.log('Refreshed FCM token.', fcmToken);
		})
		
		FirebaseX.onMessageReceived().subscribe(
				message => {
					DEBUG && console.log("Message FirebaseX.onMessageReceived: " + message);
					DEBUG && console.log("Message type: " + message.messageType);
					if(message.messageType === "notification"){
						DEBUG && console.log("Notification message received");
						if(message.tap){
							DEBUG && console.log("Tapped in " + message.tap);
							if (message.reminder_notification_id) {
								app.history.push(`/reminder-notification/${message.reminder_notification_id}`, { state: message });
								return;
							}
						}
					}
					console.dir(message);
					this.show_msg({data:message})
				}
			);
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async listen_js () {

		
		const {allowed,blocked} = await this.check_noti_perms()
		
		if (!allowed) {
			DEBUG && console.log('No notifications...', app.notifications.allowed);
			return
		}

		// TODO Check again.. I don't think we actually need this, FRB installs it automatically.
		if ('serviceWorker' in navigator) {
			await navigator.serviceWorker.register('./firebase-messaging-sw.js')
				.then(function(registration) {
						app.notifications_serviceWorker = registration;
						DEBUG && console.log('serviceWorker Registration successful, scope is:', registration.scope);
					})
				.catch(function(err) {
						DEBUG && console.log('serviceWorker Service worker registration failed, error:', err);
					});
		}

		const messaging = getMessaging(firebaseApp);
		const registration = await navigator.serviceWorker.ready;
		// messaging.usePublicVapidKey("BIDzAIJ4YKsX_L83QA2nCxq1Z45U78XNV8ivo69eobRd-bSMJkoKZ_1426i6dXPsL17HKGQ7J-MpqEBPSkOg0Ls");

		// Get Instance ID token. Initially this makes a network call, once retrieved
		// subsequent calls to getToken will return from cache.
		getToken(messaging, {vapidKey:firebase_conf.vapid_key, serviceWorkerRegistration: registration}).then( currentToken => {
			if (currentToken) {
				app.mess_tok = currentToken;
				this.send_mess_tok({tok_age:'new'})
				DEBUG && console.log('New FCM token.', currentToken);
				// sendTokenToServer(currentToken);
				// updateUIForPushEnabled(currentToken);
			} else {
				// Show permission request.
				DEBUG && console.log('No Instance ID FCM token available. Request permission to generate one.');
				// Show permission UI.
				// updateUIForPushPermissionRequired();
				// setTokenSentToServer(false);
			}	
		}).catch((err) => {
			console.log('An error occurred while retrieving FCM token. ', err);
			// showToken('Error retrieving Instance ID token. ', err);
			// setTokenSentToServer(false);
		});


		/******************************************************************************
		 *  onTokenRefresh is deprecated which is a no-op API since Version 7.18.0.   *
		 *  no token rotation would be initiated from firebase backend. It's stable.  *
		 *  https://github.com/firebase/firebase-js-sdk/issues/3994 				  *
		 ******************************************************************************/
		// Callback fired if Instance ID token is updated.
		// messaging.onTokenRefresh(() => {
		// 	messaging.getToken().then( refreshedToken => {
		// 		app.mess_tok = refreshedToken;
		// 		this.send_mess_tok({tok_age:'refr'})
		// 		console.log('Token refreshed.', refreshedToken);
		// 		// Indicate that the new Instance ID token has not yet been sent to the
		// 		// app server.
		// 		// setTokenSentToServer(false);
		// 		// Send Instance ID token to app server.
		// 		// sendTokenToServer(refreshedToken);
		// 		// ...
		// 	}).catch((err) => {
		// 		console.log('Unable to retrieve refreshed token ', err);
		// 		// showToken('Unable to retrieve refreshed token ', err);
		// 	});
		// });


		onMessage(messaging, async payload  => {
			console.log('Message received. ', payload);

			try {
				logger.usg_log('fb.onMessage', 'show_msg', null, payload, true);
				this.show_msg(payload)
			} catch (e) {
				logger.report_error('fb.onMessage', 'error', e?.message || String(e));
			}
		});

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static show_msg (msg) {
		const ignored = [
			'instcons_wr_notify',
			'instcons_doc_vidcall',
		];

		const { data } = msg
		const { mess_type,  reminder_notification_id } = (data || {})
		const { title, body } = ( msg.notification || data.notification || data.aps?.alert || data)

		if (reminder_notification_id) {
				app.trigger(app.events.SHOW_MESSAGE, 
					title, 
					<ReminderNotificationController notification={msg} compact={true} />,
					'info',
					reminder_notification_id
				)
			} else if (mess_type=='doccons_new_req') {

				app.trigger(app.events.SHOW_MESSAGE, 
								'New Doctor Consultation Request', 
								<><br/><pre>{data.msg}</pre><ActBtns row={data} /></>,
								'positive'
							)
			} else if (mess_type=='doccons_wr_full') {

				app.trigger(app.events.SHOW_MESSAGE, 
								'Doctor Consultation WR is full!', 
								<><br/><pre>{data.msg}</pre></>,
								'negative'
							)
			} else if (mess_type==='doccons_doc_vidcall' || mess_type==='doccons_doc_call') {
				app.trigger(app.events.SHOW_MESSAGE,
								msg.notification?.title || title,
								<>{msg.notification?.body || body}<br/>{data.url && <Link to={data.url}>{data.url}</Link>}</>,
								'positive'
							)

				if (data.peer_id) {
					app.vid_call = {w_peer_id: data.peer_id}
				}
			} else if (ignored.includes(mess_type)) {
				// ignore, these are just used for background notifications
			} else if (title) {

				app.trigger(app.events.SHOW_MESSAGE, 
								title, 
								<>{body}<br/>{data.url && <Link to={data.url}>{data.url}</Link>}</>,
								'positive'
							)
			} else {
				logger.report_error('ERROR in messaging_model.show_msg', 'error', {message:'cannot handle message', msg});
			}

	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------


	static async send_mess_tok (p) {

		return await API_service.load_data(
									'mess/set_tok', 
									{mess_tok: app.mess_tok, ...p},
									// r => ca && ca(r)
								) 
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async send_mess_sbsc_tpc (p) {

		return await API_service.load_data(
									'mess/subscr_tpc', 
									{mess_tok: app.mess_tok, ...p},
									// r => ca && ca(r)
								) 
	}

	// ----	--------------------------------------------	--------------------------------------------

	static async send_mess_unsbsc_tpc (p) {

		return await API_service.load_data(
									'mess/unsubscr_tpc', 
									{mess_tok: app.mess_tok, ...p},
									// r => ca && ca(r)
								) 
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async check_noti_perms() {
		
		const ret = await new Promise(resolve=>{
			if (app.settings.is_ionic)  {
				FirebaseX.hasPermission(function(hasPermission){
					DEBUG && console.log("Permission is " + (hasPermission ? "granted" : "denied"));
          app.trigger(app.events.CONTEXT, 'notifications', { allowed: hasPermission });
					resolve(hasPermission || undefined)
				});
			} else {
				// Let's check if the browser supports notifications
				if (!("Notification" in window)) {
					DEBUG && console.log("This browser does not support desktop notification");
					resolve(false)
				}
				else if (Notification.permission === "granted") {
					resolve(true)
				}
				else if (Notification.permission === "denied") {
					resolve(false)
				}
				else {
					// Notification.permission === "default"
					//console.log("Notification not available", Notification.permission, Notification);
					resolve(undefined)
				} 
				
			}
		})
		return {
			allowed:ret===true,
			blocked:ret===false
		}
		
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static support_noti() {
		
		app.support_noti = app.settings.is_ionic || ("Notification" in window && isSupported())
		return app.support_noti
		
	}
	
	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------

	static async req_noti_perms() {
		
		const {allowed,blocked} = await this.check_noti_perms()
		
		if (allowed)
			return true

		if (await new Promise((resolve,reject)=>{
			if (app.settings.is_ionic) {
          FirebaseX.grantPermission(function(hasPermission){
              DEBUG && console.log("Permission was " + (hasPermission ? "granted" : "denied"));
              app.notifications.allowed == hasPermission
              resolve(hasPermission);
              gtm.log('', 'enable_notif');
            });
			} else {
				if (!("Notification" in window)) {
					resolve(false)
				} else {
					Notification.requestPermission( permission => {
						app.notifications.allowed == permission === "granted"
						if (permission === "granted") {
							resolve(true);
							gtm.log('', 'enable_notif')
						} else {
							resolve(false)
						}
					})
				}
				
			}
		})) {
			this.listen()
			return true
		}
		
		return false
		
	}

	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------
	
	static watchChanges() {
		
		if (this.watching)
			return 
		this.watching=true
		// watch for changes
		if ('permissions' in navigator) {
			navigator.permissions.query({name:'notifications'}).then((perm) => {
				perm.onchange = _ => {
					DEBUG && console.log("User decided to change their seettings. New permission: " + perm.state);
					const notifications = {
						allowed:perm.state === "granted",
						blocked:perm.state === "denied"
					}
					app.notifications = notifications
					app.trigger(app.events.CONTEXT, 'notifications',notifications)
				};
			});
		} else {
			// backup plan? waiting loop with promise? 🤔
			(async _=>{
				const forever = true
				while(forever) {
					const notifications = await this.check_noti_perms()
					if (notifications.allowed != app.notifications.allowed || notifications.blocked != app.notifications.blocked) {
						app.notifications = notifications
						app.trigger(app.events.CONTEXT, 'notifications',notifications)
					}
					await new Promise(resolve=>setTimeout(resolve,3000))
				}
			})()
			
		}
	}
	
	// ----	--------------------------------------------	--------------------------------------------
	// ----	--------------------------------------------	--------------------------------------------


}
