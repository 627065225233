import { from_melb_ui_tm } from "../../helpers/datetime";
import { obj_map } from "../../xAppLib/helpers/obj_map";

export function maxMissedCallsText(hist) {
	return `The patient has missed ${numberOfMissedCalls(hist) + 1} calls. If you do not uncheck the checkbox below, the consultation will be cancelled, and the user will receive a refund`
}

export function numberOfMissedCalls(hist) {
	return hist?.filter?.((h) => h.diff?.part === 'status' && h.diff?.to?.status === 'noans_doccall' && h.diff?.to?.status !== h.diff?.fro?.status)?.length;
}

export function mapPtHistoryForRecentAppScripts(scripts, currentSid) {
	return (scripts ?? [])
		.filter(scr => scr.sid !== currentSid)
		.map(scr => ({
			tm: from_melb_ui_tm(scr.add_tm).toISOString(),
			script_type: scr.script_type,
			req_type: scr.req_type,
			sid: scr.sid,
			status: scr.status,
			note: scr.meta?.note,
			med: scr.med_db_data?.name,
			qnty: scr.med_db_data?.qnty,
			sz: scr.med_db_data?.size,
		}))
}

export async function authorise_medication(item) {
	const sms_verified_res = await app.sms_confirm({item }, 'SMS verification step required for this medication')
	if(sms_verified_res.verified === true) {
		return sms_verified_res
	} else {
		return false
	}
}


/**
 * Take a list of dates, or list objects with an `order`, `deliver` and `original` date and serialise to ISO format
 * For a basic schedule, these are the dates the presc will become active for the pt to order
 * For a treatment plan, the presc is scheduled based on the order date. The `deliver` and `original` dates are not
 * technically used, however useful for display and tracing purposes
 * @param {Date[] | TreatmentPlan<Date>} scheduleOrTreatmentPlan
 * @return {IsoDateString[] | TreatmentPlan<IsoDateString>}
 * @example
 * makeScheduledPrescPayload([new Date()])
 * 	=> {scheduled: ['2022-11-07T04:27:24.279Z']}
 * makeScheduledPrescPayload({ order: [new Date()], deliver: [new Date()], original: [new Date()] }])
 * 	=> {treatment_plan: {order: ['2022-11-07T04:27:24.279Z'], deliver: [...], original: [...] }}
*/
export function makeScheduledPrescPayload(scheduleOrTreatmentPlan = []) {
	if (Array.isArray(scheduleOrTreatmentPlan)) {
		return {
			scheduled: scheduleOrTreatmentPlan.map(date => date.toISOString())
		}
	}

	return {
		treatment_plan: obj_map(scheduleOrTreatmentPlan, dates => dates.map(date => date.toISOString()))
	}
}

/**
 * @typedef {string} IsoDateString
 */

/**
 * @template T
 * @typedef {Object} TreatmentPlan<T>
 * @property {T[]} order
 * @property {T[]} deliver
 * @property {T[]} original
 */

/**
 * @typedef {Object} TreatmentPlanPayload
 * @property {TreatmentPlan<IsoDateString>} treatment_plan
 */

/**
 * @typedef {Object} SchedulePayload
 * @property {IsoDateString[]} scheduled
 */