import {onDisconnect, serverTimestamp, update} from "firebase/database";
import { useCallback, useEffect, useRef } from "react";
import { useSiteStatus } from "xAppLib/Hooks"
import instcons_model from "../../models/instcons_model";
import {useCurrent} from "../../xAppLib/Hooks/useCurrent";
import firebase_database from "../../xAppLib/providers/firebase_database";
import { makeStats } from "../UIelems/OpenTokNetworkTest";

function can_approve(approve_wr = {}) {
	if (!(app.acl.is_admin || app.acl.is_doc_gp || app.acl.is_supp))
		return false
	if (Array.isArray(approve_wr.always) && (approve_wr.always.includes(app.user.claims?.doc_id) || approve_wr.always.includes(app.user.uid)))
		return true
	if (!approve_wr.active)
		return false
	return Array.isArray(approve_wr.access) ? approve_wr.access.includes(app.user.claims?.doc_id) || approve_wr.access.includes(app.user.uid) : true
}

export function useCanApprove() {
	const approve_wr = useSiteStatus('approve_wr') || {}    
    return can_approve(approve_wr)
}

export function useNurseDeviceOnline(id, record) {
	const isFinal = useCurrent(instcons_model.is_final(record));

	useEffect(() => {
		instcons_model.update_record(`${id}/req`, { vr: `${__GIT_HASH__} ${__BUILD__}` });

		const ref = firebase_database.ref(`${instcons_model.FRDB_LOC}/${id}/dvc`);
		function register_online(e) {
			if (app.user.online && (!e || e === 'UserOnlineChanged')) {
				update(ref, { online: serverTimestamp(), offline: null, vis_on: document.visibilityState })
			}
		}
		app.on(app.events.USER_UPD, register_online);
		register_online();

		const offline = {
			online: false,
			offline: serverTimestamp(),
			vis_off: document.visibilityState
		};
		onDisconnect(ref).update(offline);

		return () => {
			// don't try and update if the consult is in a terminal state,
			// we no longer need to track it and rules prevent changes anyway
			if (!isFinal.current) {
				update(ref, offline);
			}
			app.off(app.events.USER_UPD, register_online);
			onDisconnect(ref).cancel();
		};
	}, [id]);

	useEffect(() => {
		const onVisibilityChange = () => {
			const ref = firebase_database.ref(`${instcons_model.FRDB_LOC}/${id}/dvc`);
			update(ref, { vis_chg: document.visibilityState });
		}
		onVisibilityChange();
		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		}
	}, []);
}

export function usePeriodicStats({intervals_s, fn}) {
	const config = useCurrent({intervals_s, fn});
	const state = useRef({stats: [], interval: 0});

	const onStats = useCallback(next => {
		if (next?.pub?.stats) {
			try {
				if (!(
					Array.isArray(config.current.intervals_s)
					&& config.current.intervals_s.length > 0
					&& config.current.intervals_s.every(num => num > 0)
				)) {
					return;
				}

				const last = state.current.stats[state.current.stats.length - 1];
				if (last && next.itr < last.itr) {
					// new call must have started
					state.current.interval = 0;
					state.current.stats = [];
				}

				// gives us about 5 seconds of stats (assuming of course we're getting stats 1s apart)
				state.current.stats = state.current.stats.concat(next).slice(-5);

				if(state.current.stats.length < 2) {
					return;
				}

				const elapsed_s = next.elapsed / 1000;
				const interval = config.current.intervals_s.slice().sort().filter(i => i < elapsed_s).at(-1);

				if (interval && state.current.interval !== interval) {
					state.current.interval = interval;

					const prev = state.current.stats[0];

					const stats = {
						...next,
						ts: Date.now(),
						pub: {
							...next.pub,
							stats: makeStats(prev.pub.stats, next.pub.stats),
						},
						...(next.sub && {
						sub: {
							...next.sub,
							stats: makeStats(prev.sub.stats, next.sub.stats),
						},
						}),
					};

					config.current.fn(stats);
				}
			} catch (e) {
				console.error(e);
			}
		}
	}, []);

	return {
		onStats,
	};
}