import React, { useEffect, useCallback, Suspense, lazy } from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import org_model from "models/org_model";
import instcons_model from "../../../models/instcons_model";
import { get_answer_flags } from "../utils";
import { ExprConsCost } from "./Forms/ExprCons";
import WaitTimeConsultBook from "../../public/waittime/components/consult_book";
import evermed_model from "../../../models/evermed_model";
import SonicLabLocationLink from "../../UIelems/SonicLabLocationLink";
import ConsultMessage from "../../NUI/ConsultMessage";
const SonicAdvertisement = lazy(_=>import('../../UIelems/SonicAdvertisment'));

import { FRIENDLY_DATETIME_DAY_MONTH } from "../../booking/formatting";
import { getClientBookingTimezone } from "../../booking/shared";
import Alert from "../../NUI/Alert";

const Messages = (props) => {
	const { med_data, formData, cat_nm, script_type, req_type, enable_exprcons, page_state, form_send_res, is_exprcons_avail, form_res_msgs, CC_pay_err, ans_flags, show_complete, qs, is_cosm } = props
	const form_res_err_msgs = [...new Set(props.form_res_err_msgs)];

	// Checking ans_flags will show the self harm warning/alert when loading any med in that category
	// that has a previous self-harm answer. To show the generic "not suitable" message, don't include
	// ans_flags (MedForm will still block based on ans_flags including self-harm though)
	const flags = []
		.concat(ans_flags)// checking previous answers
		.concat(page_state==='form_err' ? get_answer_flags(formData, props.qs) : []) // checking form errors

	const err_msgs = (flags.includes('self_harm') && [<SelfHarmAlert />]) || (flags.includes('self_harm_warn') && [<SelfHarmWarn />]) || form_res_err_msgs || []

	const wr_key = form_send_res?.ic_wr_key || form_send_res?.cosm_wr_key;
	const req_scr_ptn = useCallback(()=> {
		// Store the Q&A _text_ for cosmetic forms. There are a _lot_ of questions on cosm forms, and while each
		// category has a lot of the same questions, they all have different IDs. This is a quick way to pre-fill
		// answers where the question/answer text is an exact match.
		app.save_pt_form_data = is_cosm ? {
			...formData,
			qa: Object.entries(qs).map(([qid, conf]) => ({
				q: conf.txt,
				a: conf.a?.[formData[qid]]?.txt,
			})).filter(qa => qa.a)
		} : formData

		delete app.save_pt_form_data.phts

		clearTimeout(app.save_pt_form_data_tmr);
		app.save_pt_form_data_tmr = setTimeout(_=>delete app.save_pt_form_data, 3*60*1000)

		// Do _not_ update this to `org_model.cosm_new_script_route()` for cosmetics (yet).
		// If the nurse has gotten to this point, they're going through the old flow, so leave them on the old flow.
		app.history.push('/c/'+(cat_nm || ''));

		if (wr_key) {
			// indicate to doctors another script is coming for this pt
			instcons_model.set_multirequest(wr_key);
		}
	},[formData, wr_key, qs, is_cosm])

	const form_type = form_send_res?.type;
	useEffect(() => {
		if (form_type === 'instcons_await') {
			instcons_model.get_pending_calls().then((recs)=>{
				app.trigger(app.events.INSTCONS_WR_PENDING, recs);
			});
		}
	}, [form_type]);

	const type = script_type=='pathoreq' && `pathology test` || req_type=='medcert' && `certificate` || `medication`;

	const msg_type = (page_state == 'form_err' || CC_pay_err || page_state == 'form_sent_err' || page_state == 'no_device') && 'error'
		|| (page_state == 'form_sent_cado_ok' || page_state == 'form_sent_ok') && 'success' || page_state == 'sending' && 'sending';

	const rec_exp_cons = enable_exprcons && (page_state == 'form_err' || page_state == 'form_sent_err') && !flags.includes('self_harm') && !flags.includes('self_harm_warn') 
		&& (formData['extra_Qs-in_australia']?.includes('yes') && !['doccons', 'cosm'].includes(script_type) || req_type == 'dc_av') && !org_model.org_conf_itm(app.runtime.org, 'hide_consults') 
		&& !app.acl.is_spon && (!["flagged", "mcvr_errors", "mcnvr_errors", "dvavr_errors", "ihivr_errors", "exceed_budget"].includes(form_send_res?.err_type) || form_send_res?.fl_err_lv == "consult")

	const dcs_wrong_ans_err = page_state == 'form_err' && script_type == 'doccons' && req_type?.startsWith("dcs_");
	const wrong_ans_err = page_state == 'form_err' && script_type == 'doccons' && req_type != 'dc_av' && !req_type?.startsWith("dcs_");
	const covid_err = page_state == 'form_err' && script_type == 'doccons' && req_type == 'dc_av';
	const cosm_err = page_state == 'form_err' && script_type == 'cosm';
	const scr_form_err = page_state == 'form_err' && script_type != 'doccons' && script_type != 'cosm' && !is_exprcons_avail;
	const form_sent_err = page_state == 'form_sent_err' && !is_exprcons_avail;
	const exp_err = (page_state == 'form_err' || page_state == 'form_sent_err') && is_exprcons_avail;

	const err_msg = [
		{
			type: dcs_wrong_ans_err,
			list: [
				<>
					Request a <Link to="/med/DocConsCov"><u>General Consultation</u></Link>.
				</>
			],
			header: "Based on your answers, we recommend that a General Consultation would be more suitable for you.",
		},
		{
			type: wrong_ans_err,
			header: "Based on your answers, this Telehealth Doctor Consultation isn't suitable for you",
		},
		{
			type: covid_err,
			header: "Unfortunately you are not eligible for covid antivirals",
			content: "However we would recommend a consultation to discuss your symptoms, give you advice and help you with any questions you may have regarding your current covid 19 illness. Please request a telehealth consultation with us to speak to a doctor.",
			consult_btn: true
		},
		{
			type: cosm_err,
			header: "Based on your answers, this treatment isn't suitable for you",
		},
		{
			type: exp_err,
			header: page_state == 'form_sent_err' ? 'Error fulfilling your request' : `Based on your answers, this ${req_type === 'medcert' ? 'medical certificate is unavailable to you through our online service' : 'medication is unavailable to you as an online prescription'}`,
			note: rec_exp_cons && <>You can request a standard consultation with a doctor related to this {req_type === 'medcert' ? 'certificate' : 'medication'} for a special cost of <ExprConsCost cart={props.cart} /></>,
			content: rec_exp_cons && <>Our doctors can only discuss this specific request and are unable to prescribe any other medications or offer other medical advice in this consultation.<br /><br/> If you have would like to discuss anything outside this specific {req_type === 'medcert' ? 'certificate' : 'medication'}, you should request a <Link to='/med/DocConsCov' className='link'>standard telehealth consultation</Link>.</>,
			consult_btn: !rec_exp_cons
		},
		{
			type: scr_form_err,
			header: `Based on your answers, this ${type} isn't available to you through our online service`,
			content: !org_model.org_conf_itm(app.runtime.org, 'hide_consults')  && 'Please consider a Telehealth Doctor Consultation',
			consult_btn: !org_model.org_conf_itm(app.runtime.org, 'hide_consults')
		},
		{
			type: CC_pay_err,
			header: CC_pay_err?.header || "There was a problem validating your payment details. - " + CC_pay_err,
			list: CC_pay_err?.messages,
			content: form_send_res?.err_type === 'paym_errors' && <>We were unable to process payment using the credit card you have selected.<br/> Please try a different card, or another payment method</>
		},
		{
			type: form_sent_err && form_send_res?.err_type !== 'req_invalid_error' && form_send_res?.err_type != 'paym_errors',
			header: "Error fulfilling your request",
			content: form_send_res?.err_type === 'flagged' ? <>We were unable to process your {type} request</> : 'Please try again later',
			extra: form_send_res?.err_type === 'flagged' && <>Please contact our{" "} <Link to="/contact-us?utm_campaign=hc&utm_content=rpts" className="text-blue-500 underline">Customer Support</Link> team for further help.</> || form_send_res?.desc,
			consult_btn: script_type != 'doccons'
		},
		{
			type: form_sent_err && form_send_res?.err_type === 'req_invalid_error',
			header: "We're sorry... Something went wrong",
			content: form_send_res?.desc,
		},
		{
			type: page_state == 'no_device',
			header: "This device is not configured",
			content: "Please contact administrator."
		}
	]

	const clientBookingTimeZone = getClientBookingTimezone();

	useEffect(() => {
		if (page_state === 'form_sent_ok' && formData?.appointment?.start) {
			app.user.reload_profile();
		}
	}, [page_state, formData?.appointment?.start]);

	return (
		<>
			{msg_type === 'error' && 
				err_msg.map((m, i) => {
					if (!m.type) return;
					return <ConsultMessage
						type='error'
						id='form-error'
						header={m?.header}
						content={m?.content}
						list={m?.list || err_msgs}
						note={m?.note}
						extra={m?.extra}
						consult_btn={m?.consult_btn}
						cta={!CC_pay_err}
					/>
				})
			}

			{page_state == 'form_err' && enable_exprcons && script_type != 'doccons' && !formData.exprcons_want &&
				<Button
					size="big"
					fluid
					color="green"
					as={Link}
					to='/med/DocConsCov'
					content='Click here to request a Telehealth Consultation with one of our doctors.'
				/>
			}

			{msg_type === 'success' && 
			  <ConsultMessage
				type="success"
				id='form-ok'
				header={
					page_state == 'form_sent_cado_ok' && "Your Doctor Consultation request is successful. Doctor will call you on your mobile within 5 minutes."
					|| page_state == 'form_sent_ok' && form_res_msgs && form_res_msgs.length > 0 && "Your script request is successful but please review the following comments:"
					|| page_state == 'form_sent_ok' && 
					(
						(['doccons', 'subcons', 'qualbuy', 'qualcons'].includes(script_type) || formData.exprcons_want) && `You've successfully requested a doctor consultation!`
						|| ['medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) && ['approved', 'doc_issued'].includes(form_send_res?.type) && `Your medication purchase request is successful.`
						|| ['medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) && `Your medication purchase request has been sent to our doctors for review. `
						|| ['approved', 'doc_issued'].includes(form_send_res?.type) &&
							`Your ${script_type === 'medsub' && 'treatment plan order' || script_type == 'pathoreq' && 'pathology request' || req_type === 'medcert' && 'medical certificate request' || 'script request'} is successful.`
						|| ['instcons_await'].includes(form_send_res?.type) && `Please proceed to video call with next available doctor.`
						|| `Your request has been sent to our doctors for review.`
					)
				}
				content={
					page_state == 'form_sent_cado_ok' && + form_res_err_msgs && form_res_err_msgs.length > 0 && `While talking to a doctor please mention following concerns: ${form_res_err_msgs}`
					|| page_state == 'form_sent_ok' && <>{
						app.settings.iscr && (
							formData?.appointment?.start
							? <div className="msg flex items-center p-4 rounded shadow md:shadow-none mb-4 bg-gray-100">
								<div data-testid='text-appointment-time'>
									Your appointment is for <strong>{moment(formData.appointment.start).tz(clientBookingTimeZone).format(FRIENDLY_DATETIME_DAY_MONTH)}</strong> ({clientBookingTimeZone} time) <sup>*</sup>
									<br />
									* Appointments times are approximate and may vary.
									<br />Please allow for additional time if necessary.
								</div>
							</div>
							: (script_type === 'doccons' && ['docconswl', 'docconswli', 'docconswlo', 'icann', 'doccons_fu'].includes(req_type)) && (
								 <div className="msg flex bg-green-50 p-4 rounded shadow md:shadow-none mb-4">
									 <h5>Average call back time is within 24 hours.</h5>
								 </div>
							 ) || (script_type === 'doccons' && ['mhcp', 'mhcprev'].includes(req_type)) && (
								<div className="msg flex bg-green-50 p-4 rounded shadow md:shadow-none mb-4">
									<h5>Average call back time is within 72 hours.</h5>
								</div>
							 ) || formData.skip_the_queue &&
									<Alert hideIcon={true} header="Your consultation has been prioritized and will be attended to ahead of standard queue times. Thank you for choosing priority access!" />
							   || ['doccons', 'exprcons', 'exprbuy', 'exprclick', 'qualbuy', 'qualcons', 'qualclick'].includes(script_type) && app.site_status.wr_stat?.use_new && (
								 <WaitTimeConsultBook basic hideIcon color="bg-gray-100"/>
							 )
						)}
						{(script_type == 'pathoreq' && app.settings.iprep && <ul>
								<li>Once approved, HIVprevent will email you a pathology referral that you must take to a pathology clinic for your test to be conducted.</li>
								<li>If you don't receive a pathology referral response from HIVprevent, please check your spam folder within your emails.</li>
								<li>If you still have not received a response from HIVprevent. please <Link to='/prep/support'>raise a support ticket with HIVprevent.</Link>.</li>
							</ul>
					    || ['doccons', 'subcons', 'qualbuy', 'qualcons'].includes(script_type) &&
						<> One of our doctors will contact you on: <span className="font-bold">{formData.cons_phone}</span></>
						|| (app.dvc.org && (app.dvc.org.type == 'pharm' || app.dvc.org.type == 'cosm') || app.user.claims?.admin || app.acl.is_doc_presc) &&
						<>
							{app.dvc.org.type == 'pharm' && <>When approved, your Electronic Prescription will be sent to you by email and SMS..<br/></>}
							You can request another script for this patient within the next 3 minutes.
							
							{script_type == 'cosm' && form_send_res && form_send_res.sid &&
								<><br/>You can view the script and add more photos here.<br/>
									<Button
										primary
										as={Link}
										to={'/script/' + (form_send_res && form_send_res.sid)}
										content='View Script'
										style={{marginTop:'.5rem'}}
									/>
								</>
							}

						</>
						|| (script_type === 'pathoreq' && show_complete) && (
								<Suspense fallback={<div>Loading...</div>}>
									<div>
										<SonicAdvertisement postcode={formData.full_address?.postcode} />
										<SonicLabLocationLink className="mt-0 text-gray-600 underline" suburb={formData.full_address?.suburb} postcode={formData.full_address?.postcode}/>
									</div>
								</Suspense>
							)
						|| ['medbuy', 'exprbuy'].includes(script_type) && ['approved', 'doc_issued'].includes(form_send_res.type) &&
							<>Soon, the pharmacy will post your order.<br /> Expected delivery time: {formData?.delivery == 'c2u' && app.tmp_c2u_serv_resp_formatted || app.tmp_aus_post_time || '1 - 5 business days'}.</>
						|| ['medbuy'].includes(script_type) && 
							<>Once it has been approved, the pharmacy will post your order.<br />Expected delivery time: {formData.delivery.includes('EM_') && evermedDeliveryLabel(evermed_model.SEL_DEL_DETS(formData.delivery)) || formData?.delivery == 'c2u' && app.tmp_c2u_serv_resp_formatted || app.tmp_aus_post_time || '1 - 5 business days'}.</>
						|| ['medclick', 'qualclick', 'exprclick'].includes(script_type) && 
							<>Once approved, your medication will be available for collection at the pharmacy you selected 2 business hours.<br /><br />
							Please wait for an email and SMS confirming your order is ready before going to the pharmacy.</>
						|| form_send_res.type == 'approved' && ['pharm', 'medcons'].includes(script_type) && 'Script has been sent to the pharmacy.'
						|| ['medcons', 'exprcons'].includes(script_type) && 'Once it has been approved you will receive a confirmation email from us.')}</>
					
				}
				extra={page_state == 'form_sent_ok' && (
					app.runtime.mode != 'caia' && ['medcons', 'exprcons'].includes(script_type) &&
					`Make sure to check your junk email folder if you don't receive an email from us within 30 minutes. If for any reason your consultation does not occur, we will reverse the pending transaction and you will not be charged.`
					|| ['doccons', 'subcons', 'qualbuy', 'qualcons'].includes(script_type) && `Please keep your phone nearby and ensure it allows calls from unknown numbers.`
				)}
				script_btn={ page_state == 'form_sent_ok' && (app.dvc.org && (app.dvc.org.type == 'pharm' || app.dvc.org.type == 'cosm') || app.user.claims?.admin || app.acl.is_doc_presc) && 'Request another script for this patient' }
				req_scr={() => req_scr_ptn()}
				note={page_state == 'form_sent_ok' && app.runtime.mode != 'caia' && ['medcons', 'exprcons'].includes(script_type) && 'We aim to send this within 5-10 minutes however it can take slightly longer after hours.'}
				list={(page_state == 'form_sent_cado_ok' || page_state == 'form_sent_ok') && form_res_msgs.length > 0 && form_res_msgs}
				class={page_state == 'form_sent_ok' && ('scr_req_suc '
					+ (req_type == 'maas' && 'scr_req_ms' || ['medbuy', 'medclick', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) && 'scr_req_mb' || script_type == 'medcons' && 'scr_req_as' || script_type == 'doccons' && 'scr_req_dc' || req_type == 'cosm' && 'scr_req_cm' || req_type == 'pharm' && 'scr_req_ph' || 'scr_req_un')
					+ ' ' + (['medbuy', 'exprclick', 'medcons', 'exprbuy', 'exprclick', 'exprcons', 'qualbuy', 'qualclick', 'qualcons', 'doccons'].includes(script_type) && 'scr_req_app' || '')
				)}
			/>}
		</>
	);
};

function SelfHarmWarn() {
	return (
		<>
			We urge you to request a telehealth consultation with one of our doctors to discuss your thoughts of self
			harm/suicide and to discuss the medication that you are currently taking. You may need a dose adjustment
			or a medication change as well as therapy from a psychologist. There are also resources like Beyond Blue
			and Lifeline that we encourage you to access.
		</>
	);
}

function SelfHarmAlert() {
	return (
		<>
			You currently have an active plan to commit suicide and as a duty of care and due to your safety you may be
			contacted by one of our staff shortly.
		</>
	);
}

const evermedDeliveryLabel = (method) => {
	return `${method.type === 'Courier' ? method?.displayName : ''} ${method?.displaySubtext}`
}

export default Messages;
