import React, { Component } from 'react';
import {
		Segment,
		Button,
		Input,
		Dropdown,
	} from 'semantic-ui-react'

import StatsPanel from './StatsPanel'
import ListTable from './ListTable'
import ListPagination from './ListPagination'
import DataConnector from './DataConnector'
import CSV_helper from '../helpers/CSV_helper'

export default class DataTable extends Component {

	constructor(props) {
		super(props);

		this.state = {
			pageSize: this.props.page_size || 30,
			page: 1,
		}
	}

    async downloadCSV() {

		let data_js = [];

        const { CSV, data } = this.props;
        for (let row of data) {
            // if (!row?.doc) continue;
            let data_row = {};
            for (let field of CSV.fields) {
                const { path, template, label } = field;
                if (!(path || template)) continue;
                data_row[label] = template ? template(row) : row[path];
            }
            data_js.push(data_row);
        }

        CSV_helper.download(
            CSV_helper.json2csv(data_js),
            CSV.name
        );

		return null;
	}
	
	render() {

		const { header, head_as, sum_rows, sum_as, data, data_rows, pre_data, left_rows, spec_rows, additional_rows, funcs, show_pagination = true, onRefresh, filt_path_srch, filt_path_opts, CSV } = this.props
		const { page, pageSize } = this.state

		let filt_opts_obj = {}
		let filt_opts_arr = []
		if (filt_path_opts) {
			data.map( d => filt_opts_obj[d[filt_path_opts]] = (filt_opts_obj[d[filt_path_opts]]||0) + 1  )
			Object.keys(filt_opts_obj).map( k => filt_opts_arr.push(({key:k, value:k, text:k + ' (' + filt_opts_obj[k] + ')'})) )
		}

		return <Segment.Group basic="true" >
			{(header || head_as || sum_rows || sum_as) && <StatsPanel 
					header = {header}
					head_as = {head_as}
					summaries = {sum_rows}
					sum_as = {sum_as}
				/>}

			{pre_data}

			<Segment>
				<DataConnector
							data={filt_path_srch && this.state.filt_srch && data.filter( d => d[filt_path_srch].toLowerCase().includes(this.state.filt_srch.toLowerCase()) ) 
									|| filt_path_opts && this.state.filt_opts?.length && data.filter( d => this.state.filt_opts.includes(d[filt_path_opts]) ) 
									|| data}
							pageSize={pageSize}
							page={page}
						>

					{ (pagedData, loading, pages, total, pageSize) => <React.Fragment>

								<ListTable data={pagedData} loading={loading} children={[
									(row, index) => 
									<React.Fragment>
											{left_rows && 
												left_rows.map( (sr, sr_i) => <ListTable.Cell 
																		row={row} 
																		index={index} 
																		key={sr+sr_i} >
																	{sr( row, index, {data, pageSize, page} )}
																</ListTable.Cell> )}

											{data_rows.filter(Boolean).map( (dr, dr_i) => {

													if (dr.hide) 
														return null

													if (dr.type=='index') 
														return <ListTable.Cell 
																		row={row} 
																		index={index} 
																		label={dr.label} 
																		key={dr+dr_i} >
																	{(value,r,index)=>(index+1+(page-1)*pageSize)}
																</ListTable.Cell>

													else
														return <>
																	<ListTable.Cell 
																		row={row} 
																		index={index} 
																		f={dr} 
																		label={dr.label} 
																		path={dr.path}  
																		key={dr+dr_i}
																		funcs={funcs}
																		extra_show={<>
																					{filt_path_srch && filt_path_srch==dr.path && !row &&
																				 		<><br/><Input
																							// placeholder={}
																							type={'text'}
																							value={this.state.filt_srch}
																							onChange={ (event, data) => this.setState({ filt_srch:data.value }) }
																							fluid={true}
																							size={'mini'}
																						 /></>
																					}
																					{filt_path_opts && filt_path_opts==dr.path && !row &&
																				 		<><br/><Dropdown
																							// placeholder={placeholder || 'Select'}
																							fluid
																							multiple={true}
																							search
																							selection
																							options={filt_opts_arr}
																							value={this.state.filt_opts || []}
																							onChange={ (event, data) => this.setState({ filt_opts:data.value }) }
																							size={'mini'}
																							style={{ height: 'auto' }}
																						 /></>
																					}
																				</>}
																	  />
																</>

												} ) }

											{spec_rows && 
												spec_rows.map( (sr, sr_i) => <ListTable.Cell 
																		row={row} 
																		index={index} 
																		label={sr( null, 'h' )} 
																		key={sr+sr_i} >
																	{sr( row, index, {data, pageSize, page} )}
																</ListTable.Cell> )}

									</React.Fragment>, ... additional_rows ? additional_rows : []]}>
								</ListTable>

                                <div className='flex flex-row'>
                                    {show_pagination && <div className='flex-1'>
                                        <ListPagination 
                                            page={page} 
                                            pages={pages} 
                                            pageSize={pageSize}
                                            loading={loading} 
                                            data={data} 
                                            total={total} 
                                            onPageChange={page=>this.setState({page})}
                                            showPageSizeSelector={true}
                                            onPageSizeChange={pageSize=>this.setState({pageSize})}
                                        />
                                    </div>}

                                    {onRefresh &&
                                        <div className='ml-4'>
                                            <Button
                                                content={'Reload'}
                                                icon="refresh"
                                                onClick={ onRefresh }
                                                color="grey"
                                                type="button"
                                                loading={loading}
                                            />
                                        </div>
                                    }

                                    {CSV &&
                                        <div className='ml-4'>
                                            <Button
                                                content={'CSV'}
                                                icon="download"
                                                onClick={() => this.downloadCSV()}
                                                color="purple"
                                                type="button"
                                                loading={loading}
                                                size='mini'
                                            />
                                        </div>
                                    }
                                </div>

							</React.Fragment>
					}

				</DataConnector>
			</Segment>
		</Segment.Group>

	}


}
