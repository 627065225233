import React from "react";
import { Link } from "react-router-dom";
import AddMedName from '../views/reminders/AddMedName';
import Schedule, { formatSchedule } from '../views/reminders/Schedule';
import { getClientBookingTimezone } from '../views/booking/shared';
import API_service from '../xAppLib/providers/API_service';
import moment from 'moment-timezone';
import { DATE_TIME_ZONE, getISOByNumber, textToTimestamp } from '../helpers/datetime';

const FORM_HAS_QTY = (values) => ['cream', 'device'].includes(values['form']);

const _MED_FORM_LIST = [
	{ text: 'Tablet', key: 'Tablet', value: 'tablet' },
	{ text: 'Capsule', key: 'Capsule', value: 'capsule' },
	{ text: 'Liquid', key: 'Liquid', value: 'liquid' },
	{ text: 'Topcial', key: 'Topcial', value: 'topcial' },
	{ text: 'Cream', key: 'Cream', value: 'cream' },
	{ text: 'Device', key: 'Device', value: 'device' }
];

const _MED_UNIT_LIST = [
	{ text: 'mg', key: 'mg', value: 'mg' },
	{ text: 'mgc', key: 'mgc', value: 'mgc' },
	{ text: 'g', key: 'g', value: 'g' },
	{ text: 'mL', key: 'mL', value: 'mL' },
	{ text: '%', key: '%', value: '%' },
	{ text: 'unit', key: 'unit', value: 'unit' },
];

const _USERS_LIST = () => {
	return app.user.profs?.map((u) => ({
		text: `${u.first_name} ${u.last_name}`,
		value: u.uid,
		key: u.uid
	}));
};

const _REMINDER_FLDS = [
	{
		name: 'client_timezone',
		type: 'hidden',
		get value() {
			return getClientBookingTimezone();
		}
	},
	{
		name: 'type',
		type: 'hidden',
		value: 'medication',
	},
	{
		name: 'patient_instructions',
		type: 'hidden',
		valid_not_required: true,
	},
	{
		name: 'reminder_id',
		type: 'hidden',
		valid_not_required: true,
	},
	{
		name: 'escript_scid',
		type: 'hidden',
		valid_not_required: true,
	},
	{
		name: 'mid',
		type: 'hidden',
		valid_not_required: true,
	},
	{
		name: 'sid',
		type: 'hidden',
		valid_not_required: true,
	},
	{
		name: 'uid',
		label: 'For',
		width: 7,
		dynamic_type: () => (app.user?.profs?.length ? 'select' : 'hidden'),
		get options() {
			return _USERS_LIST();
		},
		get value() {
			return app.user?.prof?.uid;
		}
	},
	{
		name: 'title',
		label: 'Medication name',
		content: 'Add from my history',
		width: 16,
		type: AddMedName
	},
	[
		{
			name: 'form',
			label: 'Form',
			type: 'select',
			options: _MED_FORM_LIST,
			placeholder: 'Select form',
			width: 8,
			valid_not_required: true
		},
		{
			name: 'strength',
			label: 'Strength',
			type: 'number',
			width: 4,
			placeholder: 'Strength',
			valid_not_required: true
		},
		{
			name: 'unit',
			label: 'Unit',
			type: 'select',
			options: _MED_UNIT_LIST,
			placeholder: 'Unit',
			width: 4,
			valid_not_required: true
		},
	],
	[
		{
			name: 'total_quantity',
			label: 'Total quantity',
			dynamic_type: (_, values) => FORM_HAS_QTY(values) ? 'hidden' : 'number',
			note: 'The total amount of medication in your storage container',
			width: 8,
			valid_not_required: true,
			validate_function: (val) => val >= 0,
			validation_message: () => 'Total quantity must be positive'
		},
		{
			name: 'current_quantity',
			label: 'Total remaining quantity',
			dynamic_type: (_, values) => FORM_HAS_QTY(values) ? 'hidden' : 'number',
			note: 'Indicates the amount of medication left in your storage container, helping you to manage your supply effectively',
			width: 8,
			no_empty: true,
			valid_not_required: (v) => !v['current_quantity'] && !v['remind_on_low_supply'],
			validate_function: (val, vals) => {
				if (val < 0) return false;
				if (FORM_HAS_QTY(vals)) return true;
				if (vals['remind_on_low_supply'] && !val) return false;
				return val && vals['total_quantity'] ? Number(val) <= Number(vals['total_quantity']) : true;
			},
			validation_message: (val, vals) => {
				if (val < 0) {
					return 'Total remaining quantity must be positive'
				}
				if (vals['remind_on_low_supply'] && !val) {
					return 'Total remaining quantity is required when low supply reminder is enabled';
				}
				if (val && vals['total_quantity'] && Number(val) > Number(vals['total_quantity'])) {
					return 'The total remaining quantity must be less than the total quantity';
				}
			}
		}
	],
	{
		name: 'notes',
		type: 'textarea',
		placeholder: 'Notes',
		rows: 2,
		style: { width: '100%' },
		valid_not_required: true
	},
	{
		name: 'schedule',
		type: Schedule,
		validate_function: (val) => !!val?.length && val.map(formatSchedule).every((s) => {
			if (!s.data.quantity) return false;
			if (s.frequency === 'daily') return !!s.time;
			if (s.frequency === 'weekly') return s.time && s.day;
			if (s.frequency === 'interval') return s.time && s.interval;
		}),
		validation_message: (val) => {
			if (!val?.length) return 'At least one schedule must be added';
			
			const missingFields = val.map(formatSchedule).reduce((fields, s) => {
				if (!s.data.quantity) fields.add('quantity');
				if (!s.time) fields.add('time');
				if (s.frequency === 'weekly' && !s.day) fields.add('day of the week');
				if (s.frequency === 'interval' && !s.interval) fields.add('interval');
				return fields;
			}, new Set());

			return missingFields.size ? `Missing ${Array.from(missingFields).sort().join(', ')}` : '';
		}
	},
	{
		name: 'start_time',
		label: 'Start reminders on',
		type: 'date',
		width: 5,
		valid_not_required: true,
		get value() {
			return moment(Date.now()).format(DATE_TIME_ZONE)
		},
		validate_function: (val, vals) => vals['reminder_id'] || moment(val).startOf('day').isSameOrAfter(moment().startOf('day')),
		validation_message: () => 'Start date cannot be in the past'
	},
	{
		name: 'stop_time',
		label: 'Stop reminders on',
		type: 'date',
		width: 5,
		valid_not_required: true,
		validate_function: (val, vals) => !val || moment(val).isAfter(vals['start_time']),
		validation_message: () => 'Stop date must be later than start date'
	},
	{
		name: 'remind_on_low_supply',
		label: 'Remind me to reorder more medication when my supply is low (3 days)',
		hideVal: true,
		dynamic_type: (_, values) => FORM_HAS_QTY(values) ? 'hidden' : 'bool',
		width: 16,
		value: true,
		valid_not_required: true,
	}
];

const _LIST_FIELDS = [
	{
		name: 'For',
		template: ({ value: { uid } }) => app.user.profs?.find((u) => u.uid === uid)?.first_name || uid || '',
	},
	{
		name: 'Name',
		template: ({ value: { title, reminder_id } }) => (
			<Link className="text-blue-600 dark:text-blue-500 hover:underline" to={`/reminders/${reminder_id}`}>{title}</Link>
		),
	},
	{
		name: 'Schedule',
		template: ({
			value: { schedule, form, unit, strength } }) => {
			return schedule.map(({ interval, day, time, data }) => {
				const timeStr = moment(time).format('h:mma');
				const quantity = data?.quantity;

				let frequencyStr = '';
				if (interval === 1) frequencyStr = 'Daily';
				else if (interval === 7 && day !== null) {
					const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
					frequencyStr = `Weekly on ${dayNames[day]}`;
				}
				else frequencyStr = `Every ${interval} days`;

				const strengthStr = strength ? `${strength}${unit ? ` ${unit}` : ''} ` : '';
				const quantityStr = quantity ? ` (${quantity}${form ? ` ${form}` : ''} ${strengthStr ? `of ${strengthStr}` : ''}`.trim() + ')' : '';
				return (
					<div>{frequencyStr} at {timeStr} {quantityStr}</div>
				);
			});
		},
	},
	{
		name: 'Remaining',
		template: ({value: { current_quantity = 'Unknown' } }) => current_quantity,
	}
];

const ENDPOINT = {
	get_all: 'reminders/get_all',
	get_by_rid: 'reminders/get',
	create: 'reminders/create',
	update: 'reminders/update',
	activate: 'reminders/activate',
	pause: 'reminders/pause',
	delete: 'reminders/delete',
	get_notification: 'reminders/notifications/get',
	update_notification: 'reminders/notifications/update',
	notifications_list: 'reminders/notifications/list',
	admin_notifications_list: 'admin/reminders/notifications/list',
	admin_reminders_list: 'admin/reminders/list',
};

const _REMINDER_BY_EMAIL = 'reminder_by_email';

const API_TO_FORM_PARAMS = ({ medication_data, ...rest }) => ({ ...medication_data, ...rest });

export default class reminders_model {
	static get REMINDER_FLDS() { return _REMINDER_FLDS }
	static get LIST_FIELDS() { return _LIST_FIELDS }
	static get MED_FORM_LIST() { return _MED_FORM_LIST; }
	static get REMINDER_BY_EMAIL() { return _REMINDER_BY_EMAIL; }

	static parsePatientInstructions(instructions) {
		if (!instructions || typeof instructions !== 'string') return;
		
		// replace fixes non-breaking space (ASCII code 160) versus a regular space (ASCII code 32)
		instructions = instructions.toLowerCase().trim().replace(/\s/g, ' ');

		const quantity = (() => {
			const daily_pattern = instructions.match(/take\s+(one|two|three|four|\d+)/i);
			if (daily_pattern) {
				const wordToNumber = { one: 1, two: 2, three: 3, four: 4 };
				return wordToNumber[daily_pattern[1]] || parseInt(daily_pattern[1]);
			}
			return 1;
		})();
	
		const reminders = (() => {
			const remindersRules = [
				{
					pattern: /\b(od)\b/i,
					value: 1
				},
				{
					pattern: /\b(bd|bid)\b/i,
					value: 2
				},
				{
					pattern: /\btwice a day\b/i,
					value: 2,
					exclude: /\b(?:up ?to|not more than) twice a day\b/i
				},
				{
					pattern: /\b(tds|tid)\b/i,
					value: 3
				},
				{
					pattern: /\b(qid)\b/i,
					value: 4
				}
			];

			for (const rule of remindersRules) {
				if (rule.exclude && rule.exclude.test(instructions)) {
					continue;
				}

				if (rule.pattern.test(instructions)) {
					return rule.value;
				}
			}
		})();
	
		return { quantity, reminders };
	}

	static buildValuesFromScript(script) {
		if (script.reminder_id) return script;
	
		const { spd_data, med_db_data, epresc__scid: escript_scid, sid } = script ?? {};
		const { first_name, last_name } = spd_data ?? {};
		const { name: title, epresc, m: mid, dose } = med_db_data ?? {};
		const { ItemForm, ItemStrength, Quantity, PatientInstructions } = epresc ?? {};
		const patient_instructions = PatientInstructions || dose;
	
		const form = (() => {
			const formSearch = [...reminders_model.MED_FORM_LIST.map((r) => r.value), 'actuation']?.find((m) => m.toLowerCase() === ItemForm?.toLowerCase());
			return formSearch === 'actuation' ? 'device' : formSearch;
		})();
		const unit = (() => {
			const isGram = ItemStrength?.split(' ').find((s) => s.startsWith('gram') || s === 'g');
			if (isGram) {
				return 'g'
			}
			const isMilli = ItemStrength?.split(' ').find((s) => s.startsWith('milliliter') || s.startsWith('mL'));
			if (isMilli) {
				return 'mL';
			}
	
			const units = ['mg', 'mgc', 'microgram', 'milligram', '%', 'unit'];
			const unitSearch = units.find((u) => ItemStrength?.toLowerCase().includes(u));
			const unit = {
				'milligram': 'mg',
				'microgram': 'mgc',
			}
			return unit[unitSearch] ?? unitSearch;
		})();
	
		const strength = ItemStrength?.match?.(/[\d.]+/)?.[0];
		const { uid } = app.user?.profs?.find?.((u) => u.first_name === first_name && u.last_name === last_name) ?? {};
		const total_quantity = Quantity && Number(Quantity);

		const schedule = (() => {
			const instructions = this.parsePatientInstructions(patient_instructions);
			if (instructions?.reminders) {
				return this.instructionsToSchedule(instructions);
			}
		})();

		return { sid, title, uid, form, strength, total_quantity, current_quantity: total_quantity, unit, escript_scid, mid, patient_instructions, schedule };
	}

	static instructionsToSchedule({ quantity,  reminders }) {
		const remindersTime = {
			1: [8],
			2: [8, 20],
			3: [8, 14, 20],
			4: [8, 12, 16, 20]
		}[reminders];

		if (remindersTime) {
			return remindersTime.map((i) => ({ 
				data: { quantity },
				frequency: 'daily',
				time: getISOByNumber(i),
			 }));
		}
	}

	static formParamsToApi(params) {
		if (typeof params.start_time === 'string') params.start_time = textToTimestamp(params.start_time);
		if (typeof params.stop_time === 'string') params.stop_time = textToTimestamp(params.stop_time);
		if (params.schedule) params.schedule = params.schedule.map(({ frequency, interval, type, ...row }) => ({
			...row,
			interval: interval ?? { daily: 1, weekly: 7, interval }[frequency],
			type: type || 'take_medication',
		}));
	
		if (FORM_HAS_QTY(params)) {
			delete params.remind_on_low_supply;
			delete params.current_quantity;
			delete params.total_quantity;
		}
	
		const { form, strength, total_quantity, current_quantity, unit, escript_scid, mid, patient_instructions, remind_on_low_supply, sid, ...rest } = params;
		params = {
			...rest,
			medication_data: {
				...(patient_instructions && { patient_instructions }),
				...(remind_on_low_supply && { remind_on_low_supply }),
				...(current_quantity && { current_quantity }),
				...(total_quantity && { total_quantity }),
				...(escript_scid && { escript_scid }),
				...(strength && { strength }),
				...(form && { form }),
				...(unit && { unit }),
				...(mid && { mid }),
				...(sid && { sid }),
			},
		};
	
		return params;
	}

	static async save_reminder(params) {
		const endpoint = params.reminder_id ? ENDPOINT.update : ENDPOINT.create;
		await API_service.load_data(endpoint, this.formParamsToApi(params));
	}

	static async get_all_reminders() {
		const { data = [] } = await API_service.load_data(ENDPOINT.get_all) ?? {};
		return data.map(API_TO_FORM_PARAMS);
	}

	static async get_reminder(reminder_id) {
		const { data } = await API_service.load_data(ENDPOINT.get_by_rid, { reminder_id }) ?? {};
		return API_TO_FORM_PARAMS(data);
	}

	static async update_active(isActive, reminder_id) {
		const endpoint = isActive ? ENDPOINT.pause : ENDPOINT.activate;
		await API_service.load_data(endpoint, { reminder_id });
	}

	static async delete_reminder(reminder_id) {
		await API_service.load_data(ENDPOINT.delete, { reminder_id });
	}

	static async get_notification(reminder_notification_id) {
		return await API_service.load_data(ENDPOINT.get_notification, { reminder_notification_id });
	}

	static async update_notification(data) {
		return await API_service.load_data(ENDPOINT.update_notification, data);
	}

	static async get_notifications({ page = 1, limit = 10 } = {}) {
		const offset = (page - 1) * limit;
		const { data, res } = await API_service.load_data(ENDPOINT.notifications_list, { limit, offset });
		
		if (res === 'ok') {
			return {
				notifications: data.notifications,
				total_pages: Math.ceil(data.total / limit)
			};
		}
		return { notifications: [], total_pages: 0 };
	}

	static async get_admin_notifications({ page = 1, limit = 10, search = '' } = {}) {
		const offset = (page - 1) * limit;
		const params = { limit, offset };	
		if (search) {
			params.search = search;
		}
		const { data, res } = await API_service.load_data(ENDPOINT.admin_notifications_list, params);
		
		if (res === 'ok') {
			return {
				notifications: data.notifications,
				total_pages: Math.ceil(data.total / limit)
			};
		}
		return { notifications: [], total_pages: 0 };
	}

	static async get_admin_reminders({ page, limit, search }) {
		const offset = (page - 1) * limit;
		
		const response = await API_service.load_data(ENDPOINT.admin_reminders_list, {
			limit,
			offset,
			search
		});

		if (response.res !== 'ok') {
			throw new Error(response.msg || 'Failed to fetch reminders');
		}

		return {
			reminders: response.data.reminders,
			total_pages: Math.ceil(response.data.total / limit)
		};
	}
}
