import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'site_status';

const _MODEL_NAME = 'site_model';

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

export default class site_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }

// ---- --------------------------------------------  --------------------------------------------
// ---- --------------------------------------------  --------------------------------------------

	static watch_average_wait_time(onData) {
		return this.watch_record('wr_stat/prediction/data/average_wait_time', onData);
	}
}
