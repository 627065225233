//	------------------------	------------------------	------------------------
//	Description: Interface the check eRx status and button to send script action
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'
import { 	Button,
            Icon,
            Checkbox,
            Segment,
            Accordion,
        } from 'semantic-ui-react'

import erx_model from '../../models/erx/erx_model'


import * as j_f from '../../helpers/json_functions'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const erx_heartbeat =                       30000 //ms
const input_size =                          'mini'

const type_defaults = {
    send_type_escript: true,
}

const DO_HEARTBEAT = false // PRES-12 is only a recommended requirement. https://developer.digitalhealth.gov.au/resources/electronic-prescribing-frequently-asked-questions

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

/**
 * Component for checking eRx is connected, choosing paper or escript, and button for sending script
 * @param   {Function}      onSend         onSend: Callback for when Send script button is pressed. Returns the script type ('escript' or 'paper')
 * @param   {Boolean}       sending        sending: Disable user input while parent sets this to true
 * */
class ScriptTypeSelector extends Component {

    constructor (props) {
        super(props)
        
        // Probably want to limit access here

        this.state = {
            erx_online: false,
        }

        if (props.typeDetails && props.typeDetails.script_form == 'escript') this.state.send_type_escript = true
        else if (props.typeDetails && props.typeDetails.script_form == 'paper') this.state.send_type_escript = false
        else this.state.send_type_escript = type_defaults.send_type_escript

        this.handle_props(props, false)

	}

	//	------------------------	------------------------	------------------------

	componentDidMount () {

        this.check_erx_online()
        this.setup_erx_heartbeat()

        // Make an initial call to change too
        //this.change_callback({escript: this.state.send_type_escript})
        this.post_type_change(this.state.send_type_escript)

	}

	//	------------------------	------------------------	------------------------

	componentDidUpdate(prevProps, prevState) {

        this.handle_props(this.props)

    }

    //	------------------------	------------------------	------------------------

    componentWillUnmount() {

        this.stop_erx_heartbeat()

    }

    //	------------------------	------------------------	------------------------

    handle_props(props, component_loaded = true) {

        if (this.state.loading != props.sending){
            if (component_loaded) this.setState({loading: props.sending}) //Without if, endless loop!
            else this.state.loading = props.sending
        }

        // if (typeof props.onSend == 'function') this.send_callback = props.onSend
        // else this.send_callback = _ => {console.log('onSend not bound to parent onSend function')}

        // if (typeof props.onChange == 'function') this.change_callback = props.onChange
        // else this.change_callback = _ => {console.log('onChange not bound to parent onSend function')}
        
    }
    
    //	------------------------	------------------------	------------------------
    //  Render
    //	------------------------	------------------------	------------------------

    render() {

        const patient_pref = j_f.obj_val(this, ['props', 'typeDetails', 'script_form']) // TODO don't think that's working yet
        const type_not_pref = patient_pref && ((patient_pref == 'escript' && !this.state.send_type_escript) || (patient_pref == 'paper' && this.state.send_type_escript))

        const e_supply = !!j_f.obj_val(this, ['props', 'script', 'item', 'EmergencySupply'])

        return (
            <React.Fragment>
                <h4>Script type: {this.state.send_type_escript && !e_supply && 'Electronic Script' || 'Paper script'}</h4> {/* PRES-13 - choice between paper / escript */}
                <div className='prs_cont'>
                        <div className='prs_head'>
                            {this.render_script_type()}
                            {this.render_erx_check()}
                        </div>
                        {this.state.send_type_escript &&
                            <React.Fragment>
                                <h4>An eRx barcode, QR code and PDF will be produced</h4>
                                <p>No signed script will be produced. Only an DSPID barcode will be produced which can be printed.</p>
                            </React.Fragment> || window.ERX_TEST_MODE && 
                            <React.Fragment>
                                <h4>A physical script containing the clinician's signature will be produced</h4>
                                <p>The script will still contain an DSPID barcode (ETP) if eRx is online when sent.</p>
                            </React.Fragment>}
                            {this.render_patient_pref()}
                    </div>
                    
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_patient_pref() {

        const patient_pref = j_f.obj_val(this, ['props', 'typeDetails', 'script_form'])

        return (
            <React.Fragment>
                {patient_pref &&
                    <React.Fragment>
                        <h4>Patient preference:</h4>
                        <p>{patient_pref == 'escript' && 'Electronic script' || 'Paper script'}</p>
                    </React.Fragment>
                }
            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    render_erx_check() {
        // For conformance PRES-12 - check and only allow script if ERX up
        return (
            <React.Fragment>
                <div>
                eRx status: {' '}
                <Button
                        size={input_size}
                        icon
                        color={(this.state.check_online_loading && 'yellow') || (this.state.erx_online && 'green') || 'red'}
                        loading={this.state.check_online_loading}
                        disabled={this.state.loading || this.state.check_online_loading}
                        content={<React.Fragment>{this.state.erx_online && 'Online ' || 'Offline '}<Icon name='undo' /></React.Fragment>}
                        data-testid='button-erx'
                        onClick={()=>this.check_erx_online(true)}
                    />
                </div>
            </React.Fragment>
        )
    }

    //	------------------------	------------------------	------------------------

    render_script_type() {
        // For conformance PRES-13 - choose between escript and paper script
        return (
            <React.Fragment>
                <Checkbox
                    toggle
                    label={this.state.send_type_escript && 'Electronic script' || 'Paper script'}
                    checked={this.state.send_type_escript}
                    onChange={this.handle_script_type_change.bind(this)}
                    disabled={!this.state.erx_online || this.state.check_online_loading || this.state.loading}
                />
            </React.Fragment> 
        )
    }

    //	------------------------	------------------------	------------------------
    //  Functions
    //	------------------------	------------------------	------------------------

    async handle_script_type_change(e, d){

        this.setState({send_type_escript: d.checked})
        this.post_type_change(d.checked)

    }

    //	------------------------	------------------------	------------------------

    async check_erx_online(force = false) { // PRES-12 - check eScript PDS online
        
        if (this.state.check_online_loading || this.state.loading){
            console.log('Already loading eRx online check. Please wait')
            return
        } 

        if (this.state.erx_online && !DO_HEARTBEAT && !force) {
            // console.log('Already online and no heartbeat')
            return
        }
       
       
        this.setState({check_online_loading: true})
            
        let con_res
        try {
            con_res = await erx_model.check_connection()
        } catch (error) {
            con_res = { res:'err',error}
        }

        this.setState({check_online_loading: false, erx_online: con_res.res == 'ok'}, _ => {
                
            // if (con_res.res != 'ok'){ this.handle_script_type_change({}, {checked: false}) }

        })

    }

    //	------------------------	------------------------	------------------------

    setup_erx_heartbeat() {

        if (!this.heart_beat_timer) this.heart_beat_timer = setInterval(this.check_erx_online.bind(this), erx_heartbeat)

    }

    //	------------------------	------------------------	------------------------

    stop_erx_heartbeat() {

        if (this.heart_beat_timer) {
            clearInterval(this.heart_beat_timer)
            delete this.heart_beat_timer
        }

    }

    //	------------------------	------------------------	------------------------

    async handle_prescribe() {

        if (this.state.loading || this.state.loading){
            console.log("Already sending script. Please wait.")
        } else {
            this.send_callback(this.state.send_type_escript && 'escript' || 'paper')
        }
    }

    //	------------------------	------------------------	------------------------

    post_type_change(send_type_escript) {

        // Any custom functions to check data should go in here

        this.props.onUpdate?.(send_type_escript)

    }

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default ScriptTypeSelector