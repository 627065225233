import React, { useMemo, useState } from "react";
import script_model from "models/script_model";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import UniFormMed from "views/med/UniFormMed";
import { Form } from 'semantic-ui-react';
import evermed_model from "../../../../models/evermed_model";

const deliveryETA = (delivery_method) => {
	if(delivery_method == 'c2u') return app.tmp_c2u_serv_resp_formatted
	if(delivery_method == 'home') return app.tmp_aus_post_time
	if(delivery_method?.includes('EM_')) return evermed_model.SEL_DEL_DETS(delivery_method)?.displaySubtext
	return '2 - 10 Business Days'
}

const ShippingAddressNew = (props) => {
	const { store, script_type, Section, inline=false, enable_fulfilment, header="Shipping Address", formData} = props
	const activeProfInd = formData.profile
	const fields = useMemo(() => {
		return script_model.COMM_SHIPPING_FIELDS.map(({...f})=>{
            if (f.name=='shipping_address_components') {
                f.valid_not_required = store?.delivery != 'home' && !['medbuy','exprbuy','qualbuy'].includes(script_type)
				f.type = 'address_new'
				f.activeProfInd = activeProfInd
            }
            return f
        })
	}, [script_type, store?.delivery, activeProfInd])

	const [isChecked, setIsChecked] = useState(true);

	return (
		fields.length > 0 && 
		<UniFormMed
			{...props}
			section="shipping"
			fields={fields}
		>
			{(values, valids, uf_this, fields) => {
				const args = {valids, uf_this}
				return <Section>
					<Section.Content end={true} className={inline && 'p-0'}>
						{!inline && <hr className="h-px bg-is-gray-50 border-0 -m-4"></hr>}
						<Section.Header className={inline && 'm-0 mb-3 text-md'}>{header}</Section.Header>
						{!enable_fulfilment && <h5 className="mt-0">Expected delivery time: {deliveryETA(formData?.delivery)}</h5>}
						<div>
							<Form.Checkbox
								label="Ship to my primary address"
								checked={isChecked}
								onChange={() => setIsChecked((prev) => !prev)}
							/>
							<UniFieldSUI fl={fields.shipping_address_components} {...args} show_label={!enable_fulfilment} isPrimaryAddress={isChecked} setIsPrimaryAddress={setIsChecked} showLocate />
						</div>
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
	);
};

export default ShippingAddressNew;
