import React, { useState } from 'react';
import { Button, Icon, Grid, Segment } from 'semantic-ui-react';
import { NOTIFICATION_STATUS } from './ReminderNotificationController';

const IS_SUBMITTED = (reminder) => (
	reminder.status === NOTIFICATION_STATUS.COMPLETED
	|| reminder.status === NOTIFICATION_STATUS.SKIPPED
	|| reminder.status === NOTIFICATION_STATUS.SNOOZED
);

export default function ReminderNotification({
	reminders = [],
	scheduledTime,
	scheduledDate,
	scheduledTimezone,
	onComplete,
	onSkip,
	onSnooze,
	onReminderClick,
	compact,
	onSnoozeAll,
	onSkipAll,
	onCompleteAll,
	isLoading,
}) {
	const [loaders, setLoaders] = useState();
	const hasNonMedication = reminders.some(reminder => reminder.type !== "medication");


	const loadCB = (cb, key, payload) => {
		return async () => {
			setLoaders({ [key]: true });
			cb(payload).finally(setLoaders);
		}
	}

	const canActionAll = reminders.length > 1 && !reminders?.every(IS_SUBMITTED);
	return (
		<Segment basic loading={isLoading} className="!p-0 !m-0" data-testid="reminder-banner">
			<div className={`w-full max-w-3xl mx-auto bg-white rounded-3xl shadow-lg ${compact ? 'p-3 lg:p-4' : 'p-6 lg:p-8'}`}>
				<div className={`text-center ${compact ? 'mb-2' : 'mb-6'}`}>
					<p className={`flex flex-wrap items-center justify-center gap-2 text-gray-500 ${compact ? 'text-base' : 'text-lg'}`}>
						{scheduledDate && <><span>{scheduledDate}</span> <small>( {scheduledTimezone} time )</small></>}
					</p>
				</div>

				<div className={`text-center ${compact ? 'mb-4' : 'mb-8'}`}>
					{scheduledTime && <h1 className={`${compact ? 'text-2xl lg:text-3xl' : 'text-3xl lg:text-4xl'} font-bold ${compact ? 'mb-2' : 'mb-4'}`}>{scheduledTime} Reminder</h1>}
				</div>

				<Grid columns={1} stackable>
					{reminders.map((reminder) => {
						let icon = 'calendar alternate outline';
						let details = null;
						const isMedication = reminder.type === 'medication';
						const { strength, unit, form, low_supply }  = reminder.medication_data?.[0] ?? {};

						if (isMedication) {
							icon = 'pills';
							const quantity = reminder.detail_data?.quantity ? `${reminder.detail_data.quantity}  \u00D7 ` : null;

							if (form) {
								details = (
									<>
										<p className="text-blue-500">
											{quantity} {form}
										</p>
										<p className="text-gray-500 first-letter:uppercase">
											{form}, {strength} {unit}
										</p>
									</>
								);
							}
						}

						const action = (() => {
							if (IS_SUBMITTED(reminder)) {
								const reminderStatus =
									reminder.status === NOTIFICATION_STATUS.COMPLETED ? (isMedication ? 'Taken' : 'Completed') : reminder.status === NOTIFICATION_STATUS.SKIPPED ? 'Skipped' : 'Snoozed';
								return (
									<div className={`text-sm font-medium px-3 py-1.5 rounded-full inline-block
										${reminder.status === NOTIFICATION_STATUS.COMPLETED ? 'bg-blue-100 text-blue-800' : 
											reminder.status === NOTIFICATION_STATUS.SKIPPED ? 'bg-gray-100 text-gray-800' :
											'bg-orange-100 text-orange-800'}`}>
										{reminderStatus}
									</div>
								);
							}
							return (
								<>
									<Button
										primary
										size={compact ? 'small' : 'medium'}
										title={`${isMedication ? 'Taken' : 'Completed'} reminder: ${reminder.title}`}
										content={isMedication ? 'Taken' : 'Completed'}
										onClick={loadCB(onComplete, NOTIFICATION_STATUS.COMPLETED + reminder.reminder_id, { reminder_notification_detail_id: reminder.reminder_notification_detail_id })}
										loading={loaders?.[NOTIFICATION_STATUS.COMPLETED + reminder.reminder_id]}
										disabled={loaders}
										data-testid="button-taken"
									/>
									<Button
										basic
										color="grey"
										size={compact ? 'small' : 'medium'}
										title={`Skip reminder: ${reminder.title}`}
										content="Skip"
										onClick={loadCB(onSkip, NOTIFICATION_STATUS.SKIPPED + reminder.reminder_id, { reminder_notification_detail_id: reminder.reminder_notification_detail_id })}
										loading={loaders?.[NOTIFICATION_STATUS.SKIPPED + reminder.reminder_id]}
										disabled={loaders}
										data-testid="button-skip"
									/>
									<Button
										basic
										color="orange"
										content="Snooze"
										size={compact ? 'small' : 'medium'}
										onClick={loadCB(onSnooze, NOTIFICATION_STATUS.SNOOZED + reminder.reminder_id, { reminder_notification_detail_id: reminder.reminder_notification_detail_id })}
										loading={loaders?.[NOTIFICATION_STATUS.SNOOZED + reminder.reminder_id]}
										disabled={loaders}
										data-testid="button-snooze"
									/>
								</>
							);
						})();

						return (
							<Grid.Column key={reminder.reminder_id}>
								<div className={`bg-white rounded-xl ${compact ? 'p-2 mb-2 lg:mb-3' : 'p-4 mb-4 lg:mb-6'} `}>
									<div className="flex items-center gap-4 flex-wrap">
										<a
											onClick={() => onReminderClick(reminder)}
											className="flex-grow min-w-[200px] bg-white rounded-xl p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
										>
											<div className="flex flex-wrap items-end gap-4 pb-4">
												<div className={`${compact ? 'w-8 h-8' : 'w-12 h-12'} bg-purple-400 rounded-full flex items-center justify-center`}>
													<Icon name={icon} size={compact ? 'small' : 'large'} className="text-white" />
												</div>
												<h2 className={`${compact ? 'text-lg' : 'text-xl'} font-bold`}>{reminder.title}</h2>
											</div>
											<div className="text-gray-500 pb-2">
												{reminder.user_data?.first_name} {reminder.user_data?.last_name}
											</div>
											<div>
												{details}
												{low_supply && (
													<p className="text-orange-600 mt-2 flex gap-2">
														<Icon name="warning sign" className="my-auto" />
														Running low on supply
													</p>
												)}
											</div>
										</a>
										<div className={`flex flex-col gap-3 max-w-lg w-full sm:w-auto ${compact ? 'lg:mt-4' : 'lg:mt-8'}`}>{action}</div>
									</div>
								</div>
							</Grid.Column>
						);
					})}
				</Grid>

				{canActionAll && (
					<div className={`grid grid-cols-1 sm:grid-cols-3 gap-4 max-w-lg mx-auto ${compact ? 'lg:mt-4' : 'lg:mt-8'}`}>
						<Button
							content="Snooze all"
							basic
							color="orange"
							size={compact ? 'small' : 'medium'}
							onClick={loadCB(onSnoozeAll, NOTIFICATION_STATUS.SNOOZED)}
							loading={loaders?.[NOTIFICATION_STATUS.SNOOZED]}
							disabled={loaders}
							fluid
						/>
						<Button
							content="Skip all"
							basic
							color="grey"
							size={compact ? 'small' : 'medium'}
							onClick={loadCB(onSkipAll, NOTIFICATION_STATUS.SKIPPED)}
							loading={loaders?.[NOTIFICATION_STATUS.SKIPPED]}
							disabled={loaders}
							fluid
						/>
						<Button
							content={hasNonMedication ? 'Completed all' : 'Taken all'}
							primary
							size={compact ? 'small' : 'medium'}
							onClick={loadCB(onCompleteAll, NOTIFICATION_STATUS.COMPLETED)}
							loading={loaders?.[NOTIFICATION_STATUS.COMPLETED]}
							disabled={loaders}
							fluid
						/>
					</div>
				)}
			</div>
		</Segment>
	);
}
