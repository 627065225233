import React, { lazy, Suspense } from 'react';
import script_model from 'models/script_model';
import { Form, Checkbox } from 'semantic-ui-react';
import UniFormMed from 'views/med/UniFormMed';
import { toDictionary } from "../../../icosm/treatment/utils";
import SonicLabLocationLink from '../../../UIelems/SonicLabLocationLink';
const SonicAdvertisement = lazy(_=>import('../../../UIelems/SonicAdvertisment'))

const extra = {
  '-MPGYxzJ2_x0UGF7sqMn': [
    {label: 'extra anal swab (for male-to-male sex or other non-vaginal sex)', key: 'm2m_anal'},
    {label: 'extra pharyngeal swab (for male-to-male sex or other non-vaginal sex)', key: 'm2m_pharyngeal'},
    {label: 'extra self collected vaginal swab', key: 'm2f_vaginal'},
  ],
};

const PathologyItems = (props) => {
  const { med_data, patho_request, Section, formData } = props;

  if (!patho_request) return null;

  return (
    <UniFormMed {...props} section="pathology" fields={script_model.COMM_PATHO_FIELDS}>
      {(values, valids, uf_this, fields) => {
        const { selected_items = {} } = values;
        return (
          <Section>
            <Section.Header>Pathology Tests Included</Section.Header>
            <Section.Content>
              <Suspense fallback={<div>Loading...</div>}>
                <SonicAdvertisement postcode={formData?.full_address?.postcode} addClassName='mb-6'/>
              </Suspense>
              {patho_request.tests.map(({ name, key }) => {
                const extras_disabled = !selected_items[key];
                return (
                  <Form.Field key={key}>
                    <Checkbox
                      disabled={!!med_data.itm_lock}
                      label={name}
                      checked={selected_items[key]}
                      onChange={(e, d) => {
                        const checked = !!med_data.itm_lock || d.checked;

                        // Only disable extras that are currently selected, otherwise we make it look like the patient has deselected them
                        const selected_extras = (extra[key] ?? []).filter(x => selected_items[x.key])
                        const disable_extras = !checked && toDictionary(selected_extras, x => x.key, _ => false);

                        uf_this.handleInputChange(null, { name: 'selected_items', value: { ...selected_items, ...disable_extras, [key]: checked } })
                      }}
                    />
                    {extra[key] && (
                      <Form.Field style={{ paddingLeft: '.5em', paddingTop: '.5em', display: 'flex', flexDirection: 'column' }}>
                        {extra[key].map(({ label, key }) => (
                          <Checkbox
                            label={label}
                            checked={selected_items[key]}
                            disabled={extras_disabled}
                            onChange={(e, { checked }) =>
                              uf_this.handleInputChange(null, { name: 'selected_items', value: { ...selected_items, [key]: checked } })
                            }
                          />
                        ))}
                      </Form.Field>
                    )}
                  </Form.Field>
                );
              })}
              <SonicLabLocationLink suburb={formData.full_address?.suburb} postcode={formData.full_address?.postcode}/>
            </Section.Content>
          </Section>
        );
      }}
    </UniFormMed>
  );
};

export default PathologyItems;
