import React from 'react'
import currency from 'currency.js'
import org_model from 'models/org_model'
import discounts_model from 'models/discounts_model'
import discount_engine from 'models/discount_engine'
import cat_model from 'models/cat_model'
import evermed_model from './evermed_model'
import app from 'ampersand-app'
import { get_price_exp } from '../helpers/price_exp'
import {omit} from "../helpers/json_functions";

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

const _MEDCONS_PRICE = 12.5;
const _CADO_PRICE = 71;
const _C2U_PREMIUM_PRICE = 8.95;

const DEBUG = false

export default class cart_model {
	med = null
	has_concession = false
	has_medicare = false
	want_cado = false
	want_exprcons = false
	_want_generic = false
	form_vals = {}
	del_opt = []

	constructor(args = {}) {
		Object.keys(args).forEach(k=>this[k]=args[k])
	}
		
	// 		--------------------------------		--------------------------------		---------

	price_options() {
		return [
			['No Medicare Price', currency(this.med_mb.medbuy_nomedicare_price)],
			['Medicare Price', currency(this.med_mb.medbuy_price)],
			['Concession Price', currency(this.med_mb.medbuy_conc_price)],
		].filter(p=>p[1].value)
	}

	get can_delivery() {
		return cat_model.can_deliver(this.med_mb);
	}

	// 		--------------------------------		--------------------------------		---------

	get want_generic() {
		return this.has_generic && (this._want_generic || !this.has_branded)
	}

	set want_generic(g) {
		this._want_generic = g
	}

	get has_generic() {
		return this.med_mb?.medbuy_gen_price
				&& this.med_mb.medbuy_gen_conc_price
				&& this.med_mb.medbuy_gen_nomedicare_price
	}

	get has_branded() {
		return this.med_mb?.medbuy_price
				&& this.med_mb.medbuy_conc_price
				&& this.med_mb.medbuy_nomedicare_price
	}

	// 		--------------------------------		--------------------------------		---------

	get CADO_PRICE() {
		return org_model.org_conf_itm(app.dvc.org, 'cado_price') || org_model.org_conf_itm(app.runtime.org, 'cd_pr') || _CADO_PRICE
	}

	get MEDCONS_PRICE() {
		if (this.free_consult)
			return 0

		if (this.req_type=='cosm')
			return 0

		if (this.script_type==='medsub')
			return 0;

		if (!this.med)
			return 0

		const pr_var = this.script_type=='pharm' && 'mc_ph_pr' // is there express consult for pharm...
						|| this.script_type=='medcons' && 'mc_pr'
						|| this.script_type=='exprcons' && 'xc_pr'
						|| this.script_type=='qualcons' && 'qc_pr'
						|| ['pathoreq', 'medimgreq', 'docrefr'].includes(this.script_type) && 'mc_pt_pr' // ... or patho?
						|| ['medbuy', 'medclick','medsub'].includes(this.script_type) && 'mc_mb_pr'
						|| ['exprbuy', 'exprclick'].includes(this.script_type) && 'xc_mb_pr'
						|| ['qualbuy', 'qualclick'].includes(this.script_type) && 'qc_mb_pr'

		const presc_pr = parseFloat(app?.user?.prof?.pts_prescs?.find(p => p.med?.mid==this.med.m)?.dets?.cost);
		if (pr_var && isFinite(presc_pr)) {
			return presc_pr;
		}

        let p =
            pr_var=='mc_pr' && this.req_type=='medcert' && get_price_exp('mcpt') ||
            pr_var && (
                this.med[pr_var]!=null
                    ? this.med[pr_var]
                    : app.site_status?.prs_mc[pr_var] ||
                        org_model.org_conf_itm(app.runtime.org, pr_var)
            );

		// const age = moment().diff(app?.user?.prof?.dob,'years')
		// if ((this.script_type=='medcons' || this.script_type=='medbuy') && age >= 70) {
		// 	p = org_model.org_conf_itm(app.runtime.org, 'mc_pr_70')
		// }
		
		// const p = this.script_type=='medbuy' 
		// 			? ( this.med.mc_mb_pr!=null ? this.med.mc_mb_pr : org_model.org_conf_itm(app.user.org, 'mc_mb_pr') )
		// 			: ( this.med.mc_pr!=null ? this.med.mc_pr : org_model.org_conf_itm(app.user.org, 'mc_pr') )
		// return p!=null ? p : _MEDCONS_PRICE

		return p
	}

	get DOCCONS_PRICE() {

		// console.log('DOCCONS_PRICE', this.form_vals["-M2MkLvymogzqCEG2x-l"]);
		if (!this.med)
			return 0

		if (this.req_type=='cosm')
			return 0

		const presc_pr = parseFloat(app?.user?.prof?.pts_prescs?.find(p => p.med?.mid==this.med.m)?.dets?.cost);
		if (isFinite(presc_pr)) {
			return presc_pr;
		}

		// -M2MkLvymogzqCEG2x-l: Is this consultation for a child aged under 12 months?
		// -M2MkQcJnETBryr0vBEI: Yes
		// But this question is hidden on DocConsCov and DocConsRef, so effectively this condition is never true
		if (['docconscov', 'docconsref'].includes(this.req_type) && this.has_medicare && this.form_vals['-M2MkLvymogzqCEG2x-l']=='-M2MkQcJnETBryr0vBEI') {
			return 0;
		}

		return (this.med['mc_pr'] || org_model.org_conf_itm(app.runtime.org, 'cd_pr') || _CADO_PRICE)*1;

	}

	get MEDBUY_PRICE() {
		if (!this.med)
			return 0

		else if (this.form_vals?.medication){
			return (
				(this.has_concession || this.has_dva_concession) && !this.med?.epresc?.PrivatePrescription === true && this.SELECTED_EVERMED_DETAILS.totalPrices?.concession ||
				this.has_medicare && !this.med?.epresc?.PrivatePrescription === true && this.SELECTED_EVERMED_DETAILS.totalPrices?.general  ||
				this.SELECTED_EVERMED_DETAILS.totalPrices?.original
			);
		}

		return ( this.has_concession 
					? (this.want_generic ? this.med_mb.medbuy_gen_conc_price : this.med_mb.medbuy_conc_price) 
					: (!this.has_medicare 
							? (this.want_generic ? this.med_mb.medbuy_gen_nomedicare_price : this.med_mb.medbuy_nomedicare_price)
							: (this.want_generic ? this.med_mb.medbuy_gen_price : this.med_mb.medbuy_price)
						) )*1 + org_model.org_conf_itm(app.runtime.org, 'mb_add_pr')*1
	}

	get SHIPPING_PRICE() {
		if(this.form_vals?.delivery=='c2u'){
			return _C2U_PREMIUM_PRICE
		}
		else if (this.form_vals?.delivery?.includes("EM_")){
			return currency(evermed_model.SEL_DEL_DETS(this.form_vals.delivery)?.price)
		}
		else {
			return (this.med?.xtra?.sh_mb_pr ?? org_model.org_conf_itm(app.dvc.org, 'sh_mb_pr') ?? org_model.org_conf_itm(app.runtime.org, 'sh_mb_pr') ?? 0)
		}
	}

	get PAPSCRSUB_PRICE() {
		return org_model.org_conf_itm(app.runtime.org, 'pap_scr_pr') || 5
	}

	get DELIVERY_ATTRS() {
		if(this.form_vals?.delivery == 'c2u'){
			return {
				name: "Premium Home Delivery by Chemist2U",
				desc: `Delivery ${app.tmp_c2u_serv_resp_formatted}`
			}
		} else if (this.form_vals?.delivery?.includes("EM")){
			let delOptDetails = evermed_model.SEL_DEL_DETS(this.form_vals?.delivery)
			if(delOptDetails?.type === 'Post'){
				return  {
					name: `${delOptDetails?.displayName}`,
					desc: `Delivery ${delOptDetails?.displaySubtext}`
				}
			} else if (delOptDetails?.type === 'Courier') {
				return  {
					name: `${delOptDetails?.providerName} ${delOptDetails?.type} Delivery`,
					desc: `Delivery ${delOptDetails?.displayName} ${delOptDetails?.displaySubtext}`
				}
			} else {
				return {
					name: `Loading delivery price...`,
					desc: `We're checking the new address`
				}
			}
		} else {
			return {
				name: "Australia Post" ,
				desc: `Delivery ${app.tmp_aus_post_time}`
			}
		}
	}

	get SELECTED_EVERMED_DETAILS(){
		return evermed_model.SEL_MED_DETS(this.form_vals?.medication) || {}
	}

	get USE_EVERMED(){
		return this.evermed_enabled
	}

	get SKIP_QUEUE_PRICE() {
		return  app?.site_status?.prs_mc?.skip_queue;
	}

	get skip_the_queue() {
		return Boolean(this.form_vals?.skip_the_queue);
	}

	// 		--------------------------------		--------------------------------		---------

	async get_valid_discount(code) {
		
		const { res, ...discount } = await discounts_model.get_discount_by_code(code)
		DEBUG && console.log({res, discount});
        
		return res == 'ok' && discount
		
	}

	async add_discount(discount) {
		if (!discount || discount == "") {
			this.discount = null
			return true
		}
			
		return !!(this.discount = await this.get_valid_discount(discount))
	}


	add_med(item) {
		this.med = item;
		this.med_mb = app.app_data.ws_data?.cat_data?.['pharm']?.meds_mb_list?.[item.m] || {}
	}

	suggested_products() {
		const suggested_products = this.form_vals?.suggested_products || [];
		return suggested_products.map((p, i)=>{
			return {
				id: `SUGGESTED_PRODUCT_${i}`,
				name:p.name,
				price:currency(p.price),
				tax:p.tax_type === 'taxed',
				quantity:1
			}})
	}

	apply_service_fee() {
		const service_fee_price = this.med?.xtra?.service_fee?.price;
		const script_types = this.med?.xtra?.service_fee?.script_types;
		const req_types = this.med?.xtra?.service_fee?.req_types;
		return (
			service_fee_price &&
			(script_types ? Array.isArray(script_types) && script_types.includes(this.script_type) : true) &&
			(req_types ? Array.isArray(req_types) && req_types.includes(this.req_type) : true)
		);
	}

	// 		--------------------------------		--------------------------------		---------

	content(form_err = false) {
		
		
		if (!this.med)
			return {items:[],total:currency(0),tax:currency(0),subtotal:currency(0)}

		let items, subtotal, total,  discount, discount_error, credit
		
		({ items, subtotal, total } = this.get_items(form_err));
		({ total, discount, discount_error} = this.get_discount(items, subtotal, total)) ;
		
		const { tax } = this.get_tax(items)
		const { shipping } = this.get_shipping(items);

		({ credit , subtotal, total } = this.get_credit(items,subtotal, total))
		
		return { items,total,tax, discount, subtotal, discount_error, shipping, credit }
	}
	
	get_items(form_err)  {
		const med_name = this.USE_EVERMED
			? this.SELECTED_EVERMED_DETAILS?.drugBrandName
			: this.med.name;
		const price_tag = (this.has_concession || (this.form_vals?.medication && this.has_dva_concession)) && " / Concession Price" || ''
		const sixty_day_dispense = this.med?.xtra?.is60day
			? '. This fee is for 60 days of this medication.'
			: '';
		const med_description = `${med_name}${price_tag}${sixty_day_dispense}`;

		const is_qualcons =  ['qualbuy', 'qualcons', 'qualclick'].includes(this.script_type)
		const items = [
			!form_err && (this.MEDCONS_PRICE || ['pharm', 'custadm', 'medbuy', 'medclick','medcons', 'exprbuy', 'exprclick', 'exprcons'].includes(this.script_type) || is_qualcons) && {
				name:(
					this.script_type=='pharm' && 'In Pharmacy ' ||
					this.script_type=='custadm' && 'Special Doctor ' ||
					is_qualcons && 'Digital consultation (includes a call from a doctor to review your medical history)' ||
					`${this.want_exprcons ? 'Short' : 'Digital'} Consultation Fee ${this.want_exprcons ? '(non-refundable)' : ''}`
				),
				description: this.MEDCONS_PRICE && `This fee enables our doctors to review your answers to the above questions and ${
						is_qualcons ? 'includes a call with a doctor to review your medical history' : this.want_exprcons ? 'to determine eligibility' : 'approve your request'
					}.` || '',				
				price:currency(this.MEDCONS_PRICE),
				tax:false,
				id:'MEDCONS',
				quantity:1
			},
			// Don't put medication in cart automatically if evermed is enabled.
			!form_err && (['medbuy', 'exprbuy','qualbuy','medsub'].includes(this.script_type)) && !this.USE_EVERMED && {
				name:`Medication Cost`,
				description: med_description,
				want_generic:this._want_generic,
				price:currency(this.MEDBUY_PRICE),
				tax:false,
				mid:this.med.m,
				mnm:this.med.name,
				id:'MEDBUY',
				quantity:1
			},
			// Put evermed medication in cart once selected
			!form_err && this.USE_EVERMED && this.form_vals?.medication && {
				name:`Medication Cost`,
				description: med_description,
				want_generic:this.form_vals?.medication === 'Generic',
				price:currency(this.MEDBUY_PRICE),
				tax:false,
				mid:this.med.m,
				mnm:this.med.name,
				id:'MEDBUY',
				quantity:1
			},
			...this.suggested_products(),
			this.apply_service_fee() && {
				...omit(this.med?.xtra.service_fee, ['script_types', 'req_types']),
				id: this.med?.xtra?.service_fee?.id || 'SERVICE_FEE',
				price:currency(this.med.xtra.service_fee.price),
				tax:false,
				quantity:1
			},
			this.want_cado && {
				name:"Doctor Call",
				description:'If the doctor is currently unavailable, your credit card will not be charged.',
				price:currency(this.CADO_PRICE),
				tax:false,
				id:'CADO',
				quantity:1
			},
			this.form_vals.fulfilment === 'click_collect' && this.form_vals.col_pharm && {
				name:"Click & Collect",
				description:`Click & Collect from ${this.form_vals.col_pharm.name}. Ready 2 business hours after approval.`,
				price:0,
				tax:false,
				id:'COLLECT',
				quantity:1
			},
			['doccons', 'subcons'].includes(this.script_type) && {
				name:"Doctor Telehealth Consultation",
				// description:'Doctor will call you within 1 hour. Bulk billed for eligible patients',
				price:currency(this.DOCCONS_PRICE),
				tax:false,
				id:'DOCCONS',
				quantity:1
			},
			!form_err && (['medbuy', 'exprbuy', 'qualbuy', 'medsub'].includes(this.script_type)) && this.form_vals?.delivery && {
				name:this.DELIVERY_ATTRS.name,
				description:this.DELIVERY_ATTRS.desc,
				price:currency(this.SHIPPING_PRICE),
				discounted:currency(this.DELIVERY_ATTRS.name.toLowerCase() === 'standard post' && this.discount?.conf?.free_standard_shipping ? -this.SHIPPING_PRICE : 0 ),
				tax:false,
				id:'SHIPPING',
				quantity:1,
			},
			!form_err && !['pharm', 'cosm'].includes(this.script_type) && !['pharm', 'cosm'].includes(this.req_type) && this.form_vals?.delivery=='pharm' && {
				name:"Paper script handling subcharge",
				description: 'Additional cost to process, print and send original script to the pharmacy.',
				// description:'Doctor will call you within 1 hour. Bulk billed for eligible patients',
				price:currency(this.PAPSCRSUB_PRICE),
				tax:false,
				id:'PAPSCRSUB',
				quantity:1
			},
			this.skip_the_queue && {
				name:"Priority Queue",
				price:currency(this.SKIP_QUEUE_PRICE),
				tax:true,
				id:'SKIP_QUEUE',
				quantity:1
			},
			
		].filter(n=>n)
		let subtotal = items.reduce((a,v)=>a.add(v.price),currency(0))
		let total = subtotal.add(0) // copy
		
		return { items, subtotal, total }
	}
	
	get_discount(items, subtotal, total) {
		let discount = currency(0)
		let discount_error = false
		if (this.discount) {
			try {
				discount = discount_engine.apply_discount({discount:this.discount,items,subtotal,med:this.med,script_type: this.script_type})
                // Free shipping
                discount = currency(Number(discount) + Number(items.filter(i=>i.id=='SHIPPING')[0]?.discounted || 0));

				if (discount.intValue !== 0 || this.discount.conf.always_show) {
					items.push({
						name: this.discount.code === 'op' ? '5% discount for OnePass members on Doctor Consultations' : `Discount ${this.discount.code}`,
						code:this.discount.code,
						did:this.discount.did,
						price:discount,
						discounted:currency(0),
						tax:false,
						id:'DISCOUNT',
						quantity:1
					})
					total = subtotal.add(discount)
				}
			} catch (e) {
				discount_error = e.message
			} 
			
			
		}
		
		return { total, discount, discount_error}
	}
	
	get_tax(items) {
		const tax = items.reduce((a,i)=>{
			if (i.tax) {
				return a.add(i.price.add(i.discounted).divide(11))
			}
			return a
		},currency(0))
		
		return { tax }
	}

	get_shipping(items) {
		const shipping = items.reduce((a,i)=>{
            // id is shipping, name lower case is standard post and discount is free standard shipping
			if (i.id == 'SHIPPING') {
				return a.add(i.name.toLowerCase() === 'standard post' && this.discount?.conf?.free_standard_shipping ? 0 : i.price)
			}
			return a
		},currency(0))
		
		return { shipping }
	}

	get_credit(items, subtotal,total) {
		let credit = currency(0)
		if (this.credit) {
			credit = currency(Math.min(this.credit,total) * -1)
			items.push({
				name: "Account Credit " ,
				price:credit,
				discounted:currency(0),
				tax:false,
				id:'CREDIT',
				quantity:1
			})
			total = total.add(credit)
		}

		return {credit,subtotal,total}
	}
	
}
