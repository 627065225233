import React, { useEffect,useState } from 'react';
import { Loader, Modal,Header, Button, Icon } from 'semantic-ui-react'
import Support from 'xAppLib/UIelems/Support'
import user_model from 'models/user_model'
import org_model from "../../models/org_model";
import {Link} from "react-router-dom";

 
const SupportActions = _ => {
	
	if (!app.state.loaded)
		return null

	const COSM_MAJOR_COMPLICATIONS_ROUTE = `${app.consts?.routes?.med_route || 'm'}/${org_model.org_conf_itm(app.user.org, 'meds')}/DocConsCosmMajor`
	const COSM_MINOR_COMPLICATIONS_ROUTE = `${app.consts?.routes?.med_route || 'm'}/${org_model.org_conf_itm(app.user.org, 'meds')}/DocConsCosm`

	const canPrescribe =
		(app.acl.is_nurse || app.acl.is_leg_oper || app.acl.is_doc_presc) &&
		org_model.org_conf_itm(app.user.org, 'meds') &&
		org_model.org_conf_itm(app.user.org, 'meds') != 'pharm' &&
		user_model.check_access('pg_med', org_model.org_conf_itm(app.user.org, 'meds')) &&
		!app.acl.is_admin;
	const isLegOper = app.acl.is_leg_oper;

	return <div className='support-actions' >
        
                <div>
				

				{ !user_model.check_access('show_support') && !app.settings.icosm && (app.settings.is_cbls || app.settings.is_cmg) &&
					<Button
							className="support-button" compact size="large" color="orange" icon="talk" content_="Contact Us"
							onClick={ _=> app.trigger( app.events.SHOW_POPUP, {
																				"tp": "pu",
																				"pt": "contact",
																				"txt": "Contact Us",
																				"u": "contact-us"
																		} )	}
						/>
				}

				{ user_model.check_access('show_support') &&
					<Support />
				}

				{canPrescribe && !isLegOper && (
					<div className={'ml-2'}>
						<Link to={COSM_MAJOR_COMPLICATIONS_ROUTE}>
							<Button className="!bg-icosm-tertiary support-button !text-white" compact size_="large" data-testid="button-major-compl" icon>
								Major Complication <Icon name="exclamation circle" />
							</Button>
						</Link>
						<Link to={COSM_MINOR_COMPLICATIONS_ROUTE}>
							<Button className="support-button left" compact size_="large" color="orange" data-testid="button-minor-compl">
								Minor Complication
							</Button>
						</Link>
					</div>
				)}

                </div>

			</div>
};

export default SupportActions
