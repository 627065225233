//	------------------------	------------------------	------------------------
//	Description: Displays reasons for prescription based on server lookup
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

import React, { Component } from 'react'

import { Input, Checkbox, Table, Pagination, Dropdown } from 'semantic-ui-react'

import debounce from 'xAppLib/libs/debounce'

import erx_model from '../../models/erx/erx_model'
import { PRESCRIBED_ITEM_PROPS } from '../../models/erx/resources/erx_props'

//	------------------------	------------------------	------------------------
//	Globals
//	------------------------	------------------------	------------------------

const debounce_time =           250 //ms

const table_size_list = [1, 2, 5, 10]

const table_size_options = table_size_list.map(v => {return {key: `drop_${v}`, text: `${v}`, value: v}})

const reason_prop = PRESCRIBED_ITEM_PROPS.find(a => a.name == 'ReasonForPrescribing')

const reason_max_len = reason_prop && reason_prop.length || 50

//	------------------------	------------------------	------------------------
//	Classes
//	------------------------	------------------------	------------------------

class ReasonPrescribeSelector extends Component {

    constructor (props) {
        super(props)


        const item = props.script?.item || {}

        let extra ={ }

        if (item.ReasonForPrescribing && item['SNOMED-CTReasonforPrescribe']) {
            extra = {
                reason_res : {
                    items:[
                        {
                            "_id": "1",
                            "conceptId": item['SNOMED-CTReasonforPrescribe'],
                            "term": item.ReasonForPrescribing,
                            
                          }
                    ]
                },
                total_pages:1,
                total_count:1
            }
           
        }

        this.state = {
            reason_data:        [],
            reason_loading:     false,
            disorder:           true,
            finding:            false,//true,
            reason_res:         null,
            reason_text:        item.ReasonForPrescribing || '',
            reason_id:          item['SNOMED-CTReasonforPrescribe'] || 0,
            page_size:          10,
            active_page:        1,
            total_pages:        1,
            total_count:        0,
            truncated_str:      false,
            ...extra
        }

        this.debounce_reason_filter = debounce(this.set_reason_filter.bind(this), debounce_time, false)

        this.search_index_req = 0
        this.search_index_res = 0

	}

	//	------------------------	------------------------	------------------------

	componentDidMount () {

        

	}


    //	------------------------	------------------------	------------------------

    render() { // Wrapping context

        return (
            <React.Fragment>

                <h4>Clinical indication</h4>
                <Input
                    placeholder={'Type reason or search terms'}
                    value={this.state.reason_text}
                    onChange={(e, d) => this.handle_reason_input(d.value, 0)}
                    loading={this.state.reason_loading}
                    maxLength={reason_max_len}
                    fluid
                    disabled={!!this.props.view_mode}
                />

                {this.state.reason_text && this.state.reason_text.length == reason_max_len &&
                    <p className='txt_valid_warning'>Maximum length {reason_max_len} characters.{this.state.truncated_str && ' SNOMED-CT reason truncated.'}</p>
                }
                <h5>SNOMED indications (optional)</h5>
                <Table celled>{/*striped will break the css*/}
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Reason</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    
                    <Table.Body>
                        {
                            this.state.reason_res && this.state.reason_res.items && Array.isArray(this.state.reason_res.items) && (

                                this.state.reason_res.items.length == 0 &&
                                <Table.Row disabled={this.state.reason_loading}><Table.Cell>No results. Alter search term(s) to find indications.</Table.Cell></Table.Row>
                                ||
                                this.state.reason_res.items.map((c, i) => {
                                    return (
                                        <Table.Row disabled={this.state.reason_loading} key={`t_${i}`} onClick={_ => { !this.props.view_mode && this.handle_row_click(c) }} className={'select_row'}>
                                            <Table.Cell>{c.conceptId || '?'}</Table.Cell>
                                            <Table.Cell>{c.term || '?'}</Table.Cell>
                                        </Table.Row>
                                    )
                                })

                            )
                            ||
                            <Table.Row disabled={this.state.reason_loading}><Table.Cell>Enter search terms to find indications</Table.Cell></Table.Row>
                        }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                {`Total results: ${this.state.total_count}`}
                                {`, results per page: `}
                                <Dropdown
                                    placeholder={'Page size'}
                                    //selection
                                    value={this.state.page_size}
                                    options={table_size_options}
                                    onChange={(e, d) => { this.setState({ page_size: d.value }, _ => { this.debounce_reason_filter() }) }}
                                />
                                <Pagination floated='right' activePage={this.state.active_page} totalPages={this.state.total_pages} firstItem={null} lastItem={null} onPageChange={(e, d) => { this.setState({ active_page: d.activePage }, _ => { this.debounce_reason_filter() }) }} />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                {!this.props.view_mode && <>
                <Checkbox
                    //className='field_check'
                    toggle
                    checked={this.state.disorder}
                    label={'Disorders'}
                    onChange={_ => { this.setState({ disorder: !this.state.disorder }, _ => this.debounce_reason_filter()) }}
                />

                <Checkbox
                    //className='field_check'
                    toggle
                    checked={this.state.finding}
                    label={'Findings'}
                    onChange={_ => { this.setState({ finding: !this.state.finding }, _ => this.debounce_reason_filter()) }}
                />
                </>}

            </React.Fragment>
        )

    }

    //	------------------------	------------------------	------------------------

    handle_row_click(r) {

        this.setState({truncated_str: false}, _ =>{

            const str_end = '...'

            let term = r.term, truncated = false

            if (typeof term == 'string' && term.length > reason_max_len){ // Make sure the string isn't too long
                term = `${term.slice(0, reason_max_len - str_end.length)}${str_end}`
                truncated = true
            }

            this.handle_reason_input(term, r.conceptId, truncated)

            if (typeof this.props.onSelect == 'function') this.props.onSelect()

        })

    }

    //	------------------------	------------------------	------------------------
    
    handle_reason_input(reason, id, truncated = false) {

        this.setState({reason_text: reason, reason_id: id, active_page: 1, truncated_str: truncated}, _ => {this.debounce_reason_filter()})

    }

    //	------------------------	------------------------	------------------------

    async set_reason_filter(){

        this.update_filtered_reasons()
        //this.get_field_auto()
        // console.log('BW reason update')

    }

    //	------------------------	------------------------	------------------------

    async update_filtered_reasons() {

        this.search_index_req += 1

        const c_search_index = this.search_index_req // By value needed as async

        const options = {
			page: this.state.active_page,
			page_size: this.state.page_size,
            text: this.state.reason_text,
            disorder: this.state.disorder,
            finding: this.state.finding,
        }

        // Save to script
        if (this.props.script.item){

            const scr_item = {
                ReasonForPrescribing : this.state.reason_text,
                ['SNOMED-CTReasonforPrescribe'] : this.state.reason_id
            }

            this.props.onUpdate?.(scr_item)

        }
        
        this.setState({reason_loading: true}, async _ => {

            const reason_res = await (new erx_model()).get_reasons(options)

            if (c_search_index > this.search_index_res) {
                this.search_index_res = c_search_index

                const state_update = { reason_res, reason_loading: false }
                if (reason_res.items && Array.isArray(reason_res.items) && reason_res.items.length > 0){
                    state_update.total_count = reason_res.items[0].total_count
                    state_update.total_pages = Math.ceil(state_update.total_count / this.state.page_size)
                } else {
                    state_update.total_count = 0
                    state_update.total_pages = Math.ceil(state_update.total_count / this.state.page_size)
                }

                this.setState(state_update)
            }

		})

    }

}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

export default ReasonPrescribeSelector