import React, { useEffect, useMemo } from 'react';
import { Button, Dropdown, Grid, Header, Icon, FormField, FormInput, Message, Label } from 'semantic-ui-react';
import { getISOByNumber, timestampToTime, timeToTimestamp } from '../../helpers/datetime';
import { getClientBookingTimezone } from 'views/booking/shared';

const QUANTITY_OPTIONS = Array.from({ length: 4 }, (_, i) => ({
	key: i,
	text: `Qty ${i + 1}x`,
	value: i + 1
}));

const FREQUENCY_OPTIONS = [
	{ key: 'daily', text: 'Daily', value: 'daily' },
	{ key: 'weekly', text: 'Weekly', value: 'weekly' },
	{ key: 'interval', text: 'Interval of', value: 'interval' }
];

const DAY_OPTIONS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, i) => ({ key: day, text: day, value: (i + 1) % 7 }));

const INTERVAL_OPTIONS = Array.from({ length: 59 }, (_, i) => ({
	key: i,
	text: `${i + 2} days`,
	value: i + 2
}));

const DEFAULT_ROW = [{ data: { quantity: 1 }, frequency: 'daily', time: getISOByNumber(8) }];

const PRESETS = [
	{ key: 'once', label: 'One daily' },
	{ key: 'twice', label: 'Twice daily' },
	{ key: 'thrice', label: 'Three times daily' },
	{ key: 'custom', label: 'Custom' }
];

export const formatSchedule = ({ interval, frequency, ...row }) => ({
	...row,
	frequency: frequency ?? { 1: 'daily', 7: row.day ? 'weekly' : 'interval' }[interval] ?? 'interval',
	...(!row.day && interval > 1 && interval && { interval }),
});

export default function Schedule({ onChange, error, value: schedule, values, fl = {} }) {
	const rows = useMemo(() => {
    if (schedule?.length) {
        return schedule.map(formatSchedule);
    }
    return DEFAULT_ROW;
	}, [schedule]);

	useEffect(() => {
		if (!schedule?.length) {
				onChange({ target: { name: fl.name, value: DEFAULT_ROW } });
		}
	}, [onChange, schedule, fl.name]);

	const handlePresetSchedule = (preset) => () => {
		const newRows = {
			once: DEFAULT_ROW,
			twice: [...DEFAULT_ROW, { data: { quantity: 1 }, frequency: 'daily', time: getISOByNumber(20) }],
			thrice: [
				...DEFAULT_ROW,
				{ data: { quantity: 1 }, frequency: 'daily', time: getISOByNumber(14) },
				{ data: { quantity: 1 }, frequency: 'daily', time: getISOByNumber(20) }
			],
			custom: DEFAULT_ROW.map(({ time: _omitted, ...rest }) => rest),
		}[preset];

		onChange({ target: { name: fl.name, value: newRows } });
	};

	const handleQuantityChange = (index) => {
		return (_, { value }) => {
			onChange({ target: { name: fl.name, value: rows.map((row, i) => (i === index ? { ...row, data: { quantity: value } } : row)) } });
		}
	};

	const handleFrequencyChange = (index) => {
		return (_, { value }) => {
			onChange({ target: { name: fl.name, value: rows.map((row, i) => (i === index ? { ...row, frequency: value } : row)) } });
		}
	};

	const handleDayChange = (index) => {
		return (_, { value }) => {
			onChange({ target: { name: fl.name, value: rows.map((row, i) => (i === index ? { ...row, day: value } : row)) } })
		}
	};

	const handleTimeChange = (index) => {
		return (_, { value }) => {
			onChange({ target: { name: fl.name, value: rows.map((row, i) => (i === index ? { ...row, time: timeToTimestamp(value) } : row)) } });
		}
	};

	const handleIntervalChange = (index) => {
		return (_, { value }) => {
			onChange({ target: { name: fl.name, value: rows.map((row, i) => (i === index ? { ...row, interval: value } : row)) } });
		}
	};

	const addRow = () => {
		const newRow = {
			data: { quantity: 1 },
			frequency: 'daily'
		};
		onChange({ target: { name: fl.name, value: [...rows, newRow] }});
	};

	const removeRow = (index) => {
		return () => {
			onChange({ target: { name: fl.name, value: [...rows.slice(0, index), ...rows.slice(index + 1)] }});
		}
	};

	return (
		<FormField>
			<Header>Reminder Schedule ({getClientBookingTimezone()})</Header>
			
			{values?.patient_instructions && (
				<div className="my-4">
					<Icon name="info circle" color="grey" />
					<span className="text-gray-500 font-semibold">Patient Instructions: </span>
					<span className="text-gray-700">{values.patient_instructions}</span>
				</div>
			)}

			{error?.content && (
				<Message negative>
					<Message.Content>{error.content}</Message.Content>
				</Message>
			)}

			<div className="flex flex-wrap gap-4 mb-6">
				{PRESETS.map((p) => (
					<Label key={p.key} className="cursor-pointer hover:opacity-80" onClick={handlePresetSchedule(p.key)} data-testid="label-preset">
						{p.label}
					</Label>
				))}
			</div>

			{rows.map((row, index) => (
				<Grid padded stackable key={index}>
					<Grid.Row className="!px-0 !py-2 [&>*]:!px-2">
						<Grid.Column width={3}>
							<Dropdown fluid selection options={QUANTITY_OPTIONS} value={row.data?.quantity} onChange={handleQuantityChange(index)} />
						</Grid.Column>
						<Grid.Column width={4}>
							<Dropdown fluid selection options={FREQUENCY_OPTIONS} value={row.frequency} onChange={handleFrequencyChange(index)} />
						</Grid.Column>
						{row.frequency === 'weekly' && (
							<Grid.Column width={4}>
								<Dropdown placeholder="Day" fluid selection options={DAY_OPTIONS} value={row.day} onChange={handleDayChange(index)} error={!row.day && error} />
							</Grid.Column>
						)}
						{row.frequency === 'interval' && (
							<Grid.Column width={4}>
								<Dropdown
									placeholder="Interval"
									fluid
									selection
									options={INTERVAL_OPTIONS}
									value={row.interval}
									onChange={handleIntervalChange(index)}
									error={!row.interval && error}
								/>
							</Grid.Column>
						)}
						{row.frequency && (
							<Grid.Column width={4}>
								<FormInput fluid type="time" value={timestampToTime(row.time) || ''} onChange={handleTimeChange(index)} error={!row.time && !!error} />
							</Grid.Column>
						)}
						{rows?.length > 1 && (
							<Grid.Column width={1} className="!flex !justify-center">
								<Button icon="x" color="red" onClick={removeRow(index)} />
							</Grid.Column>
						)}
					</Grid.Row>
				</Grid>
			))}
			<Button onClick={addRow} primary className="!mt-6">
				<Icon name="plus" />
				Add another time
			</Button>
		</FormField>
	);
}
