import React, { useState, useEffect, useRef, useCallback } from "react";
import firebase_database, { watchers } from "../providers/firebase_database";
import site_model from 'models/site_model'
import exprms_model from 'models/exprms_model'
import dmyt_to_ymdt from 'xAppLib/helpers/dmyt_to_ymdt'
import logger from "../libs/logger";

const DEBUG = false

const AppContext = React.createContext({});

function ensure_number(val, fallback) {
	if (val !== null && isFinite(+val)) {
		return +val;
	}
	return fallback;
}

const AppProvider = ({ children }) => {
	const histRef = useRef([])
    const [appData,setAppData] = useState({ 
		hist:[], 
		site_status:{},
		notifications: {
			allowed:false,
			blocked:false
		},

		exprms: exprms_model.local(),
	})


	useEffect( _=> app.on(app.events.DVC_LDD, _=> setContext({device_ready:true}) ) , [] )

	useEffect(()=>{
		if (!appData.site_status.ready)
			return
		if (appData.site_status?.exprms) {
			setContext({ exprms: exprms_model.calc_exprms(appData.site_status?.exprms) })
		}
		
		if (appData.device_ready && exprms_model.need_upd) {
			exprms_model.save_to_dvc() // persist to device
		}
	}, [appData.device_ready, appData.site_status])

	// Emulate A/B test data coming later						
	// setTimeout( _=> {app.runtime.login_type = ['page', 'popup'][1]}, 2000)

	const setContext = useCallback((keyOrData,data) => {
		if (typeof keyOrData === 'string')
			setAppData(state=>({...state,[keyOrData]:data}))
		else
			setAppData(state=>({...state,...keyOrData}))
	},[])

    useEffect(()=>{
		
		app.on(app.events.CONTEXT, setContext)
        
		const prs_update = (data)=>setContext("prs",data)
		app.on(app.events.PRS, prs_update)
        
		const unlisten = app.history.listen((location,action) => {
            // console.log("***",location, action);
			if (action=='PUSH') {
				histRef.current.push(location)
			}
			else if (action == "POP") {
				histRef.current.pop()
			}
			setContext({hist:[...histRef.current]})
		});

		function gtag() {dataLayer.push(arguments)}
		gtag('event', 'optimize.callback', {
				callback: (v, n) => {
					DEBUG && console.log('optimize.callback new', n, v)
					if (v===undefined)
						return
					setAppData(state=>{
						const { experiments = {} } = state
						if (experiments[n]===v)
							return state
						experiments[n]=v
						app.runtime.optim = experiments
						app.runtime.login_type = ['page', 'popup'][experiments['1_JCh2sTTQK-Qht3c21KoQ'] * 1]
						return ({...state,experiments})
					})
				}
			});

		const siteOff = site_model.watch_record(
			"",
			r => {
				app.site_status = {...r}

				// fallback to Brigitte
				app.site_status.cosm_supp_ph ||= '0410 948 004';
				app.site_status.cosm_emergency_ph ||= '0434 579 776';
				app.site_status.cosm_emergency_nm ||= 'Dr Mira Klein';

				app.site_status.WR_wait_avg_mins ||= "30"
				app.site_status.WR_wait_max_hrs ||= "2"

				app.site_status.instcons_dr_skype_fallback_delay ||= "10"
				app.site_status.instcons_skype_fallback_delay ||= (app.settings.dev_env_local ? "30" : "300")

				app.site_status.WR_wait_DocConsHairIni_hrs ||= "30"
				app.site_status.WR_wait_DocConsWL_hrs ||= "30"
				app.site_status.WR_wait_DocConsCov_hrs ||= ""

				app.site_status.rtdb_bounce_delay_mins ||= 15;

				app.site_status.appscr_max_lock = ensure_number(app.site_status.appscr_max_lock, 0);
				app.site_status.appscr_review_delay = ensure_number(app.site_status.appscr_review_delay, 0);

				app.site_status.new_user_disc_cons ||= false;

				app.site_status.disable_paypal ??= {android: false, ios: true, web: false}

				app.site_status.enable_suggested_products ||= false

				if (dmyt_to_ymdt(__BUILD__)*1 < dmyt_to_ymdt(app.site_status[app.settings.is_ionic ? 'ver_app' : 'ver_web'].toString())*1) {

					console.log('ver check err', app.site_status, "__BUILD__", __BUILD__, dmyt_to_ymdt(__BUILD__) );
					app.site_status.newVersion=true
				}
				else 
					app.site_status.newVersion=false
				
				app.site_status.ready = true
				

				setContext({site_status:app.site_status})
			}
		)

       return () => {
			app.off(app.events.CONTEXT, setContext)
			app.off(app.events.DVC, prs_update)
			unlisten()
			siteOff()
	   }
            
    },[])

	useEffect(() => {
		// There seems to be an issue with the RTDB connection becoming stale for
		// If the app is added as a home screen app on iOS, sometimes after waking from sleep,
		// sometimes RTDB won't detect that it was offline so doesn't reconnect. It *thinks* it
		// is still online though! This came to light when some nurse terminals didn't seem to be
		// respecting the instcons toggle, but also not having the consult load despite it being
		// in RTDB and the ic_wr_key returned to the frontend. Bouncing the connection periodically
		// brings it back online and synced.
		// This was a known issue in Chromium and since been fixed, but maybe it's still broken in Safari:
		// https://github.com/firebase/firebase-js-sdk/issues/2802#issuecomment-607707331
		let last_bounce = 0;
		function bounce_rtdb({force = false} = {}) {
			// -1 to disable rtdb_bounce
			if (!app.site_status)
				return
			const debounce_ms = 1000 * 60 * app.site_status.rtdb_bounce_delay_mins;
			if (debounce_ms > 0 && (force || Date.now() - last_bounce > debounce_ms)) {
				const count = watchers.count();
				logger.usg_log('bounce_rtdb', JSON.stringify({force, count}));
				last_bounce = Date.now();
				firebase_database.bounce_connection();
			}
		}

		app.on(app.events.BOUNCE_RTDB, bounce_rtdb);
		return () => {
			app.off(app.events.BOUNCE_RTDB, bounce_rtdb);
		};
	}, []);

	const slow_rtdb_threshold = ensure_number(app.site_status?.log_slow_rtdb, 0);
	useEffect(() => {
		if (slow_rtdb_threshold <= 0) {
			return;
		}

		let eventCount = 0;
		let lastHangEvent = 0;
		return firebase_database.onData((snapOrDbRef, type, description, count, elapsed) => {
			const maxEventLogs = ensure_number(app.site_status?.slow_rtdb_limit, 5);
			const timeSinceLastHang = Date.now() - lastHangEvent;

			DEBUG && console.log('AppContext.frdb.onData', {
				eventCount,
				timeSinceLastHang,
				count,
				elapsed,
				slow_rtdb_threshold,
				maxEventLogs,
			});

			// Only care about the first load of data (i.e. first time a watch resolves, or first time a value is read)
			if (count > 1) return;

			if (elapsed < slow_rtdb_threshold) return;

			// After a site wide hang event, there'll likely be _heaps_ of slow queries that will resolve suddenly . So
			// to avoid spamming logs, we'll just log the first X slow queries within the "slow query" threshold times
			// some arbitrary multiplier. If it's been longer than that threshold, reset the event count and start
			// logging again. If our "slow" query is 30 seconds, we'll back off for 50 minutes which seems reasonable
			const throttle = slow_rtdb_threshold * 100;
			if (timeSinceLastHang > throttle) {
				lastHangEvent = Date.now();
				eventCount = 0;
			} else {
				eventCount++
			}

			// Too many events, stop logging for a bit
			if (eventCount > maxEventLogs) {
				return;
			}

			const size = snapOrDbRef?.val ? JSON.stringify(snapOrDbRef.val()).length : -1;
			logger.usg_log('slow_rtdb', location.pathname, null, {type, description, elapsed, size, eventCount});
		});
	}, [slow_rtdb_threshold]);

	return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
};

function AppConsumer({ children }) {
	return (
		<AppContext.Consumer>
			{(context) => {
				if (context === undefined) {
					throw new Error(
						"AppConsumer must be used within a AppProvider"
					);
				}
				return children(context);
			}}
		</AppContext.Consumer>
	);
}


export { AppContext, AppProvider, AppConsumer };
