
import React, { Component, useCallback, useMemo, useState } from 'react'
import { Confirm, Button, Label, Header, Icon, Modal, Segment, Accordion, Table, Grid, Popup, Input } from 'semantic-ui-react'

import PrescribedItemSelector from 'views/erx/PrescribedItemSelector'
import PatientSelector from 'views/erx/PatientSelector'
import ClinicianSelector from 'views/erx/ClinicianSelector'
import ScriptTypeSelector from 'views/erx/ScriptTypeSelector'
import { useAsync } from 'xAppLib/Hooks/useAsync'
import API_service from 'xAppLib/providers/API_service'
import { authorise_medication } from '../../doc/util'

const ScriptSummary = ({row,modal,trigger, onRefresh}) => {

	const [open,setOpen] = useState(false)

	const { sid } = row

	const script = useMemo(()=>{

		const { item, clinician, patient, mysl_consent,escript,send_to_pharmacy } = row?.epresc || {}
		if (!item)
			return null
		return {
			item,
			patient,
			clinician,
			escript,
			mysl_consent,
			send_to_pharmacy,
		}
	
	},[row])

	const status_action = useAsync({
		immediate: open,
		fn: async () =>  API_service.load_data('escripts/status', {sid})
	}, [open,sid]);

	const { data : status, loading : status_loading } = status_action

	const epresc = status?.epresc || script?.epresc || {}

	const state = epresc.state || 'Submitted'

	const amend_action = useAsync({
		immediate: false,
		fn: async (item) =>  {

			let sms_verif
			if ((item.ScheduleNumber * 1) >= 8 ) {
				const sms_verified_res = await authorise_medication(item)
				if(!sms_verified_res?.verified) {
					return
				}
			
				sms_verif = sms_verified_res
			}

			const res = await API_service.load_data('escripts/amend_script', {sid,item,sms_verif}).catch(e=>{
				app.functions.add_message({type: 'negative', header:'Failed to update script', content:e||'Unknown Error'})
			})
			if (res?.res=='ok'){
				app.functions.add_message("Script Updated")
				onRefresh?.()
			}
			return res
		}
	}, [sid]);

	if ( script == null)
		return null

	// const active = !['Dispensed','Cancelled','Ceased'].includes(state)

	let content =  <Segment basic loading={amend_action.loading || status_loading} className='!p-0'>

			<div className='my-2'>
				<h4>Status {state}</h4>
				{/* TODO add toolbox to revoke/cancel/recreate*/''}
			</div>

			<PrescribedItemSelector
				script={script}
				itemDetails={script.item}
				viewOnlyMode={true}
				canEdit={script.clinician && script.clinician.PrescriberHPII == app.user.doc_det?.hpii}
				onUpdateScript={amend_action.fn}
			/>

			<PatientSelector
				script={script}
				patientDetails={script.patient}
				viewOnlyMode={true}
			/>

			<ClinicianSelector
				script={script}
				clinicianDetails={script.clinician}
				viewOnlyMode={true}
			/>
	</Segment>


	if (modal) {

		content = <Modal
			closeIcon
			open={open}
			onOpen={()=>setOpen(true)}
			onClose={()=>setOpen(false)}
			trigger={trigger||<Button>View Details</Button>}
		>
			<Modal.Header>eScript details</Modal.Header>
			<Modal.Content>
				{content}
			</Modal.Content>
			<Modal.Actions>
				<Button color='green' onClick={()=>setOpen(false)}>
					Close
				</Button>
			</Modal.Actions>
		</Modal>


	}

	return content


}
 



export default ScriptSummary
