import React, { useEffect, useReducer } from 'react';
import { Button, Dropdown, Modal, ModalContent, ModalHeader, Input, Loader, Icon, Popup, Segment, Radio } from 'semantic-ui-react';
import { useDisclosure } from '../../xAppLib/Hooks/useDisclosure';
import GetViewScreen from './GetViewScreen';
import MyHRDirectoryView from './MyHRDirectoryView';
import GetMyHRIndividualDetails from './GetMyHRIndividualDetails';
import UploadDocumentButton from './UploadDocumentButton';
import moment from 'moment';
import GainAccess from './GainAccess';
import SHS from './SHS';

const ENABLE_MY_HR_VIEW = true

const SHOW_TYPE_CODE_FILTER = true

const THAT_MYHR_MOOD_COLOUR = 'brown'

const defaultFormData = {
	view_type: 'Health Record Overview',
	start_date: '2012-01-01',
	end_date: moment().format('YYYY-MM-DD'),
	class_codes: [],
	type_codes: [],
};

const defaultState = {
	form_data: defaultFormData,
	loading: false,
	document_data: false,
	filter: false, 
	has_access: false, 
};

const patientRecordsReducer = (state, action) => {
	switch (action.type) {
		case 'setLoading':
			return {
				...state,
				loading: action.value,
			};
		case 'handleInputChange':
			const { name, value, params = {} } = action;
			return {
				...state,
				form_data: {
					...state.form_data,
					[name]: value,
					...params
				},
			};

		case 'applyFilters':
			return {
				...state,
				filters: state.form_data,
			};
		case 'resetState':
			return defaultState;

		case 'matchFormDataWithFilters':
			return {
				...state, 
				form_data: state.filters				
			}

		case 'setAccessStatus': 
			return {
				...state, 
				has_access: true
			}
		default:
			return state;
	}
};

const GetMyHRPatientRecords = ({ by_uid, children, doc, sid, onRefresh, disableUpload, src_obj, scripts  }) => {
	if (!ENABLE_MY_HR_VIEW || !app.acl.can_view_myhr)
			return  null

	if (!by_uid)
		return null

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen : isSHSOpen, onOpen: onOpenSHS, onClose: onCloseSHS } = useDisclosure();
	const [state, dispatch] = useReducer(patientRecordsReducer, defaultState);

	useEffect(() => {
		if (!isOpen) {
			dispatch({ type: 'resetState' });
		} else {
			dispatch({ type: 'applyFilters' });
		}
	}, [isOpen]);

	const handleInputChange = (e, { name, value, document }) => {
		dispatch({ type: 'handleInputChange', name, value, params:{document} });
		if (name=='view_type')
			dispatch({ type: 'applyFilters' });
	};

	const handleSearch = async () => {
		dispatch({ type: 'applyFilters' });
	};

	const onceGranted = () => {
		dispatch({type: 'setAccessStatus'})
	}

	const isSearchingGetViewType = state.has_access && displayOptions
		.filter((opt) => opt.value !== 'All Documents')
		.map((opt) => opt.value)
		.includes(state.filters?.view_type);
	const isSearchingDocumentViewType = state.has_access && ['All Documents'].includes(state.filters?.view_type);

	const searchMatchesFilters = JSON.stringify(state.filters) === JSON.stringify(state.form_data);
	const disableDateRangeSelector = state.form_data.view_type === 'Health Record Overview';
	const enableClassCodeSelector = state.form_data.view_type === 'All Documents';

	const myhr = src_obj?.myhr || {}

	return (
		<>
			{children?.(onOpen, onOpenSHS) || <Button color={THAT_MYHR_MOOD_COLOUR} onClick={onOpen} type='button'>My HR</Button>}
			<SHS isOpen={isSHSOpen} onOpen={onOpenSHS} onClose={onCloseSHS} src_obj={src_obj} scripts={scripts} />
			<Modal size={'fullscreen'} open={isOpen} onClose={onClose} closeIcon dimmer={'inverted'}>
				<ModalHeader className='!pr-0'>
					<div className='flex justify-between'>
						<h4 className='flex-auto'>My Health Record Data</h4>
						{!disableUpload && <span>
							<UploadDocumentButton granted={!!myhr.grant} uid={by_uid} sid={sid} doc={doc} onRefresh={onRefresh}  />
						</span>}
					</div>

					
				</ModalHeader>
				
				<ModalContent className='inline-block'>
					<GetMyHRIndividualDetails uid={by_uid} has_access={state.has_access}/>
					<div className='flex flex-col mb-2 gap-2'>
						<div className='flex justify-between'>
							<div className='flex flex-col xl:flex-row gap-2'>
								<Dropdown
									style={{ minWidth: '300px' }}
									name='view_type'
									placeholder='View Type'
									selection
									options={displayOptions}
									value={state.form_data.view_type}
									onChange={handleInputChange}
								/>

								<Input
									name='start_date'
									label='Start Date'
									type='date'
									disabled={disableDateRangeSelector}
									placeholder='Start Date'
									value={state.form_data.start_date}
									onChange={handleInputChange}
								/>

								<Input
									name='end_date'
									label='End Date'
									type='date'
									placeholder='End Date'
									disabled={disableDateRangeSelector}
									value={state.form_data.end_date}
									onChange={handleInputChange}
								/>
							</div>
							<div className='flex flex-row gap-2 items-center'>
								{state.filters && JSON.stringify(state.filters) !== JSON.stringify(state.form_data) && (
									<Popup
										content={
											'Search terms are different to document being displayed. Press search to loading view/document matching search or click this icon to revert back to current filters.'
										}
										trigger={<Icon name='refresh' onClick={() => dispatch({ type: 'matchFormDataWithFilters' })} />}
									/>
								)}
								<Button primary onClick={() => handleSearch()} disabled={searchMatchesFilters}>
									Search
								</Button>
							</div>
						</div>
						<div className=''>
							{enableClassCodeSelector && (
								<div className='flex flex-col space-y-2'>
									<Dropdown
										multiple
										fluid
										selection
										placeholder={'Class Code Filters'}
										name='class_codes'
										options={documentTypeOptions}
										className='!h-[fit-content]'
										value={state.form_data.class_codes}
										onChange={handleInputChange}
										search
									/>
									{SHOW_TYPE_CODE_FILTER && <Dropdown
										multiple
										fluid
										selection
										placeholder={'Type Code Filters'}
										name='type_codes'
										options={documentTypeOptions}
										className='!h-[fit-content]'
										value={state.form_data.type_codes}
										onChange={handleInputChange}
										search
									/>}
								</div>

							)}
						</div>
					</div>
					{!state.has_access && <GainAccess {...{ uid: by_uid, onceGranted}} />}
					{isSearchingGetViewType && <GetViewScreen {...{ uid: by_uid, filters: state.filters, changeView: handleInputChange }} />}
					{isSearchingDocumentViewType && <MyHRDirectoryView {...{ by_uid, filters: state.filters }} doc={doc} auto_open={state.form_data.document} />}
				</ModalContent>
			</Modal>
		</>
	);
};

export default GetMyHRPatientRecords;


export const MyHRListBtn = ({row,myhr,pts_uid,onRefresh,disableUpload}) => {
	if (!ENABLE_MY_HR_VIEW)
		return null
	const content = <><b>My HR:</b>&nbsp;
			{myhr?.grant && <>
				<Icon name='check' />
				{!!myhr?.doc?.documentId && <><Icon name='pin' /> {myhr.doc.version}</>}
			</>
			|| <Icon name='dont'  />}</>
	if (!app.acl.can_view_myhr)
		return <>{content}</>
	return <GetMyHRPatientRecords by_uid={pts_uid} src_obj={row} doc={myhr?.doc} sid={row.sid} onRefresh={onRefresh} disableUpload={disableUpload}>
			{(onOpen)=><>
				<Button color={THAT_MYHR_MOOD_COLOUR} size='mini' onClick={onOpen}>{content}</Button>
			</>}
			
		</GetMyHRPatientRecords> ;
}

export const MyHRConsult = ({req_scr_obj,scripts, onToggleUpload, myhr, extended=false}) => {
	return <GetMyHRPatientRecords by_uid={req_scr_obj.pts_uid} src_obj={req_scr_obj} scripts={scripts}>{(onOpen,onOpenSHS) => 
		<div className={`mb-4  -mt-${extended?0:8}`}> 
		   {extended && <b>My Health Record</b>}
			
		   <Segment className='flex justify-between !p-2'>
			   <div className="flex items-start">
				   <Radio
						   toggle
						   checked={!!myhr}
						   label='Upload records to My HR'
						//    onChange={ () => this.setState({myhr:!this.state.myhr })}
						   onChange={ onToggleUpload }
					   />
				   
			   </div>
			   <div className="flex justify-between items-start space-x-2">
				   <Button  size='mini' color="blue" onClick={onOpen}>Open Patient MyHR</Button>
				   {extended && <Button basic size='mini' onClick={onOpenSHS}>Add Shared health summary</Button>}

			   </div>

	   
			   
			</Segment>
		</div>
	 }</GetMyHRPatientRecords> 
}
 

const displayOptions = [
	'Health Record Overview',
	'Medicare Overview',
	'Prescription and Dispense View',
	'Pathology Report View',
	'All Documents'
  ].map(opt => ({key: opt, value: opt, text: opt}));

const documentTypeOptions = [
	// These need to match BE ClassCodes Alternative Name to populate
	// ConceptCode and CodingSystem
	'Shared Health Summary',
	'e-Referral',
	'Specialist Letter',
	'Discharge Summary',
	'Event Summary',
	'Pharmaceutical Benefits Report',
	'Australian Organ Donor Register',
	'Australian Immunisation Register',
	'Medicare/DVA Benefits Report',
	'Prescription and Dispense View',
	'Health Check Schedule View',
	'Observation View',
	'Medicare Overview',
	'Medicare Overview - all',
	'Medicare Overview - past 12 months',
	'Personal Health Note',
	'Personal Health Summary',
	'Advance Care Directive Custodian Record',
	'eHealth Prescription Record',
	'eHealth Dispense Record',
	'Diagnostic Imaging Report',
	'Pathology Report',
	'Advance Care Information',
	'Advance Care Planning Document',
	'Goals of Care Document',
	'Health Check Assessment',
	'Child Parent Questionnaire',
	'Consumer Entered Achievements',
	'Consumer Entered Measurements',
	'Birth Details',
	'Medicines View',
	'Pharmacist Shared Medicines List',
	'Pathology Overview',
	'Diagnostic Imaging Overview',
	'Immunisation Consolidated View',
	'Residential Care Health Summary',
	'Patient Health Summary',
	'Residential Care Medication Chart',
	'Medication Chart',
	'Residential Care Transfer Reason',
	'Transfer Summary',
	'Residential Care Transfer Overview',
	'MyMedicare Registered Practice Information',
]
	.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
	.map((opt) => ({ key: opt, value: opt, text: opt }));
